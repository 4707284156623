import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, NavbarCollapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CopyButton from './CopyButton';
import ConfirmationModal from './ConfirmationModal';


const AdminFunctionPanel = (props) => {

    return (
        <Panel>
            {props.actions.map((action, index) => {
                return (
                    <Button
                        variant={action.variant || "primary"}
                        style={{marginRight: "10px"}}
                        onClick={action.onClickFunction}
                    >
                        {action.label}
                    </Button>
                );
            })}
        </Panel>
    );

};

export default AdminFunctionPanel;

AdminFunctionPanel.propTypes = {
    actions: PropTypes.array.isRequired,
};


const Panel = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #f2f2f2;
    z-index: 999;
    padding: 5px 0px;
`;