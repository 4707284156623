import styled from 'styled-components';
import ListView from '../components/ListView.js';
import NewEntry from './NewEntry.js';
import { useParams } from 'react-router-dom';
import { getAgreementVersionsAdmin, createAgreementVersionAdmin } from '../services/api/admin_agreements.js';


const AdminAgreementVersions = (props) => {

    const { agreement_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "agreement_id",
            "label": "Agreement ID",
            "type": "copy",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "version",
            "label": "Version",
            "type": "string",
            "detailLink": "/admin/agreements/"+agreement_id+"/versions/<id>",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "updated_on",
            "label": "Updated At",
            "type": "string",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "download_link",
            "label": "Download",
            "type": "external_link",
            "filterable": false,
            "settable": false,
        }
    ];

    return (
        <Page>
            <ListView
                title="Admin: Agreements"
                list_method={getAgreementVersionsAdmin}
                list_query_params={{'agreement_id': agreement_id}}
                attributes={attributes}
                paginate={true}
                max_per_page={50} />
            <NewEntry
                title="Admin: Agreements"
                create_method={createAgreementVersionAdmin}
                attributes={attributes}
                base_data={{'agreement_id': agreement_id}} />
        </Page>
    )
}

export default AdminAgreementVersions;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;