import { url_api, api_version, admin_api_version } from '../../sources.js';
import axios from 'axios';
import { store } from '../redux/store.js';
import { clearUser } from '../redux/userSlice.js';

//wrapper for GET method with error handling
export function get(api_path, api='api', query_params={}) {
    let version = api_version;
    if (api === 'admin') {
        version = admin_api_version;
    }
    let url = url_api + version + api_path + paramsToString(query_params);

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (response.status === 401)
      {
        handleAuthError();
      }
      else if (!response.ok)
        throw new Error('Network error:' + response.statusText);
      return response.json()
    })
}

//wrapper for POST method with error handling
export function post(api_path, data, api='api', contentType='application/json') {

    let version = api_version;
    if (api === 'admin') {
        version = admin_api_version;
    }
    if (contentType === 'application/json') {
        data = JSON.stringify(data);
    }

    return fetch(url_api + version + api_path, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type':  contentType,
        },
        body: data
      })
      .then(response => {
        if (response.status === 401)
        {
          handleAuthError();
        }
        else if (!response.ok)
          throw new Error('Network error:' + response.statusText);
        return response.json()
    })
}


//wrapper for DELETE method with error handling
export function del(api_path, api='api') {
    let version = api_version;
    if (api === 'admin') {
        version = admin_api_version;
    }
    
    return fetch(url_api + version + api_path, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response.status === 401)
        {
          handleAuthError();
        }
        else if (!response.ok)
          throw new Error('Network error:' + response.statusText);
        return response.json()
    })
}

//wrapper for PATCH method with error handling
export function patch(api_path, data, api='api', contentType='application/json') {
    let version = api_version;
    if (api === 'admin') {
        version = admin_api_version;
    }
    if (contentType === 'application/json') {
      data = JSON.stringify(data);
    }

    return fetch(url_api + version + api_path, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': contentType,
        },
        body: data
      })
      .then(response => {
        if (response.status === 401)
        {
          handleAuthError();
        }
        else if (!response.ok)
          throw new Error('Network error:' + response.statusText);
        return response.json()
    })
}

//wrapper for put method using axios
export async function axios_put(url, body) {
    const response = await axios({
        method: 'put',
        url: url,
        withCredentials: false,
        data: body,
        headers: {
            'Content-Type': 'video/mp4',
        }
    })
    return response.data
}

export function handleAuthError() {
  let unsubscribe = store.subscribe(
    () => {
      if (store.getState().user.value === null) {
        unsubscribe();
      }
    }
  );
  store.dispatch(clearUser());
  unsubscribe();
  // if (window.location.pathname !== '/login') { 
  //   window.location.pathname = '/login';
  // }
}

export function paramsToString(params) {
    if (params === undefined) {
        return '';
    }
    let param_string = '?';
    for (const [key, value] of Object.entries(params)) {
        param_string += `${key}=${value}&`
    }
    return param_string.slice(0, -1);
}
