import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles, resetState, cancelUpload, removeUpload } from '../services/redux/fileUploadSlice';
import { useWakeLock } from "react-screen-wake-lock";
import styled from 'styled-components';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { COLORS } from '../values/colors';
import { reduceString } from '../services/util/metadata';
import { Modal, Button, ProgressBar} from 'react-bootstrap';
import { addNotification } from '../services/redux/notificationsSlice';
import { hmsFromSeconds } from '../services/util/metadata';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

const UploadProgressWidget = () => {

    const { isSupported, released, request: requestWakeLock, release: releaseWakeLock } = useWakeLock({
        // onRequest: () => console.log('Screen Wake Lock: requested.'),
        // onError: () => console.log('Screen Wake Lock: rejected.'),
        // onRelease: () => console.log('Screen Wake Lock: released.'),
    });

    const dispatch = useDispatch();
    const uploads = useSelector((state) => state.fileUpload.uploads);
    const [fileURLs, setFileURLs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalID, setModalID] = useState(null);
    const firstRun = React.useRef(true);

    useEffect(() => {

        if (isSupported && firstRun.current && Object.values(uploads).length>0) {
            firstRun.current = false;
            requestWakeLock();
            return;
        }
        if (isSupported && Object.values(uploads).length>0 && released) {
            requestWakeLock();
        } else if (isSupported && Object.values(uploads).length===0 && !released) {
            releaseWakeLock();
        }

        setFileURLs(Object.values(uploads).map((upload) => upload.fileURLs || null));
    }, [uploads]);

    const handleCancel = (id) => {
        dispatch(cancelUpload({ id }));
        try {
            mixpanel.track('Upload Canceled', {'Source': 'Upload Progress Dialog', 'Attachment ID': id});
        } catch (error) {
            console.log('Error tracking upload canceled', error);
            Sentry.captureException(error);
        }
    };

    const handleMoreInfo = (id) => {
        //open a modal with more information about the upload
        setModalID(id);
        setShowModal(true);
        try {
            mixpanel.track('Upload Info Viewed', {'Source': 'Upload Progress Widget', 'Attachment ID': id});
        } catch (error) {
            console.log('Error tracking upload info viewed', error);
            Sentry.captureException(error);
        }
    };


    const handleReset = (id) => {
        dispatch(removeUpload({id}));
    };

    const handleRetry = (id) => {
        const caseData = uploads[id];
        console.log('Retrying upload', id, caseData)
        dispatch(uploadFiles({
            attachment_id: id,
            caseName: caseData.caseName,
            caseDescription: caseData.caseDescription,
            files: [],
            checksums: caseData.checksums,
            totalSize: caseData.totalSize,
            videoLength: caseData.videoLength,
            isRetry: true
        }))
        try {
            mixpanel.track('Upload Resumed', {'Source': 'Upload Progress Widget', 'Attachment ID': id});
        } catch (error) {
            console.log('Error tracking upload retried', error);
            Sentry.captureException(error);
        }
    };   

    return (
        <>
        <WidgetContainer>
            {Object.entries(uploads).map(([id, upload]) => {
                let color = COLORS.orchid_teal
                let pathcolor = COLORS.light_gray
                let onClick
                let icon1
                let icon2
                let text
                if (upload.status==='interrupted')
                {
                    color='red'
                    onClick = () => handleRetry(id)
                    icon1 = '/images/icons/x-symbol.svg'
                    icon2 = '/images/icons/refresh-cw-02.svg'
                    text = 'Retry'
                }
                else if (upload.status==='uploading')
                {
                    color=COLORS.orchid_teal
                    onClick = () => handleMoreInfo(id)
                    icon1 = '/images/icons/upload-cloud-01.svg'
                    icon2 = '/images/icons/info-circle.svg'
                    text = 'More Info'
                }
                else if (upload.status==='completed')
                {
                    color='green'
                    onClick = () => handleReset(id)
                    icon1 = '/images/icons/cloud-blank-01.svg'
                    icon2 = '/images/icons/x-symbol.svg'
                    text = 'Dismiss'
                }
                else
                {
                    color='red'
                    pathcolor='darkred'
                    onClick = () => handleReset(id)
                    icon1 = '/images/icons/x-symbol.svg'
                    icon2 = '/images/icons/x-symbol.svg'
                    text = 'Dismiss'
                }

                return (
                <UploadProgressContainer
                    key={id}
                    onClick={onClick}
                >
                    <CircularProgressbarContainer>
                        <CircularProgressbarWithChildren
                            value={upload.progress}
                            strokeWidth={10}
                            minValue={1}
                            styles={{
                                text: {
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                },
                                path: { stroke: color },
                                trail: { stroke: pathcolor },
                            }}
                        >
                            {upload.status==='interrupted' ?
                            <>
                            <PrimaryIcon src={icon1} />
                            <SecondaryIcon src={icon2} />
                            </>
                            :
                            <>
                            <PrimaryIcon src={icon1} />
                            <SecondaryIcon src={icon2} />
                            </>
                            }
                        </CircularProgressbarWithChildren>
                    </CircularProgressbarContainer>
                    <TitleText>"{reduceString(upload.caseName, 19)}"</TitleText>
                    <TooltipText>{text}</TooltipText>
                </UploadProgressContainer>
                )
            }
            )}
        </WidgetContainer>
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Case Name: {uploads[modalID]?.caseName}</p>
                    {uploads[modalID]?.caseDescription &&
                    <p>Case Description: {uploads[modalID]?.caseDescription}</p>}
                    {/* <p>Progress: { Math.round(uploads[modalID]?.totalSize/10*uploads[modalID]?.progress/(1024*1024*1000))/10}GB/{Math.round(uploads[modalID]?.totalSize/(1024*1024*1000)*10)/10}GB</p> */}
                    <p>Progress: { 
                        uploads[modalID]?.totalSize > 1000*1000*1000 ?
                        `${Math.floor(uploads[modalID]?.totalSize / (1000 * 1000 * 1000) * uploads[modalID]?.displayedProgress/100 * 10) / 10} / ${Math.round(uploads[modalID]?.totalSize/(1000 * 1000 * 1000) * 10) / 10} GB` :
                        `${Math.floor(uploads[modalID]?.totalSize / (1000 * 1000) * uploads[modalID]?.displayedProgress/100 * 10) / 10} / ${Math.round(uploads[modalID]?.totalSize/(1000 * 1000) * 10) / 10} MB`
                    }</p>
                    <p>Speed: {Math.round(uploads[modalID]?.progressRate / (1000 * 1000) * 10 ) / 10} MB/s</p>
                    <p>
                        Estimated Time Remaining: {uploads[modalID]?.progressRate > 0 ?
                        hmsFromSeconds(Math.round((uploads[modalID]?.totalSize - uploads[modalID]?.totalSize*uploads[modalID]?.displayedProgress / 100) / (uploads[modalID]?.progressRate))) :
                        'Calculating...'}
                    </p>
                    <div style={{width: '100%', height: '100%', position: 'relative'}}>
                    <ProgressBar style={{backgroundColor: '#cccccc'}}
                        now={uploads[modalID]?.progress} 
                        animated = { uploads[modalID]?.progress < 100}
                    />
                    <ProgressPercentage>
                        {`${Math.round(uploads[modalID]?.progress)}%`}
                    </ProgressPercentage>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {handleCancel(modalID); setShowModal(false)}} 
                disabled={uploads[modalID]?.status==='completed' || uploads[modalID]?.status==='interrupted'}
                >Cancel Upload</Button>
                <Button variant="primary" onClick={() => setShowModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default UploadProgressWidget;

const WidgetContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const CircularProgressbarContainer = styled.div`
    width: 40px;
    height: 40px;
    padding: 5px;
    `;

const UploadProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 280px;
`;

const PrimaryIcon = styled.img`
    display: flex;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: height 0.3s ease-in-out;

    ${UploadProgressContainer}:hover &{
        height: 0;
    }
`;  

const SecondaryIcon = styled.img`
    display: flex;
    height: 0;
    transition: height 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);

    ${UploadProgressContainer}:hover &{
        height: 18px;
    }
`;

const TitleText = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-align: left;
    color: #333333;
    font-family: 'Poppins';
    font-size: 16px; 
    width: auto;
    overflow: hidden;
    transition: width 0.3s ease-in-out;

    ${UploadProgressContainer}:hover &{
        width: 0;
    }
`;

const TooltipText = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    color: #333333;
    font-family: 'Poppins';
    font-size: 16px; 
    width: 0px;
    overflow: hidden;
    transition: width 0.3s ease-in-out;

    ${UploadProgressContainer}:hover &{
        width: auto;
    }
`;

const ProgressPercentage = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 12px;
    font-weight: bold;
    z-index: 2;
`;