import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import { requestMFA, verifyMFA } from '../services/api/me.js';
import MfaInput from './MfaInput.js';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const EnableMfaModal = (props) => {

    const [mfaUri, setMfaUri] = useState(null);
    const [mfaCode, setMfaCode] = useState('');
    const [mfaError, setMfaError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const requestMfaCode = () => {
        requestMFA().then((data) => {
            setMfaUri(data.uri);
        });
    }

    const submitMfaCode = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        verifyMFA(mfaCode).then((data) => {
            if (!data) {
                setMfaError('Invalid MFA code.');
            } else {
                console.log(data);
                setMfaError(null);
                setMfaUri(null);
                props.onSuccess();
            }
        }).finally(() => {
            setMfaCode('');
            setIsSubmitting(false);
        });
    }

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Enable Multi-Factor Authentication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { !mfaUri && 
                    <Button onClick={requestMfaCode}>Request a New Code</Button>
                }
                {mfaUri &&
                    <div>
                        <b>Step 1.</b> Scan the QR code below with your authenticator app.
                        <QRWrapper>
                            <QRCode value={mfaUri} />
                        </QRWrapper>
                        <b>Step 2.</b> Input the code from your authenticator app to verify it.
                        <MfaInput
                            label="Enter the code from your authenticator app."
                            onChange={setMfaCode}
                            value={mfaCode}
                        />
                    </div>
                }
                {mfaError &&
                    <p className="text-danger">{mfaError}</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button variant="primary" onClick={submitMfaCode} disabled={isSubmitting || mfaCode.length !== 6}>Verify MFA</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EnableMfaModal;

EnableMfaModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

const QRWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;
