import styled from 'styled-components';

export function parseTimestamps(comment, playerRef) {
    //this function takes a comment string and returns a comment with the video timestamp transformed into links
    //timestamps are of the form @hh:mm:ss or @mm:ss or @ss
    //timestamps are transformed into links to the video at the specified time

    //regex to find timestamps
    const timestampRegex = /(@(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d))/g;
    //regex to find the hours, minutes, and seconds of the timestamp

    //find all timestamps in the comment
    const timestamps = comment.match(timestampRegex);

    //if there are no timestamps, return the comment as is
    if (!timestamps) {
        return (
            <>{comment}</>
        );
    }

    let commentParts = comment.split(new RegExp(timestamps.map(item => `(${item})`).join('|'), 'g'))
    //remove undefined from the array
    commentParts = commentParts.filter(item => item !== undefined)

    //convert a timestamp to seconds
    const convertTimestampToSeconds = (timestamp) => {
        //split the timestamp into hours, minutes, and seconds
        const [hh, mm, ss] = timestamp.split('@')[1].split(':').map((item) => parseInt(item));
        
        if (!ss && !mm) {
            return hh
        } else if (!ss) {
            return mm + hh * 60
        } else {
            return ss * 1 + mm * 60 + hh * 3600
        }
    };

    const StyledLink = styled.a`
        color: #007bff !important;
        text-decoration: none;
        cursor: pointer;
    `;

    // return the comment with the timestamps replaced with links
    return(
        < >
            {commentParts.map((part) => {
                //if the part is a timetsamp, return a link to the video at that timestamp
                if (timestamps.includes(part)) {
                    return (
                        <StyledLink  key={part}
                            onClick={() => playerRef.current.currentTime(convertTimestampToSeconds(part))}
                        >{part}</StyledLink>
                    )
                }
                //otherwise return the part as is
                return (
                    <>{part}</>
                )
            }
            )}
        </>
    )

}