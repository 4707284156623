import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { get } from '../services/api/common.js';


const Admin = () => {
    return (
        <Page>
            <h1>Admin</h1>
            <LinkPanel>
                <h4>Management Panels</h4>
                <StyledLink to='/admin/users'>Users</StyledLink><br />
                <StyledLink to='/admin/groups'>Groups</StyledLink><br />
                <StyledLink to='/admin/agreements'>Agreements</StyledLink><br />
                <StyledLink to='/admin/organizations'>Organizations</StyledLink><br />
                <StyledLink to='/admin/tags'>Tags</StyledLink><br />
                <hr />
                <StyledLink to='/admin/metrics'>Metrics</StyledLink><br />
            </LinkPanel>
            <LinkPanel>
                <button onClick={() => {throw new Error("test")}}>Trigger Frontend Error</button>
                <button onClick={() => get('/error/', 'admin')}>Trigger Backend Error</button>
            </LinkPanel>
        </Page>
    )
}

export default Admin;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;

const LinkPanel = styled.div`
    position: relative;
    display: block;
    gap: 5px;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.6);
    width: 1000px;
    margin: 20px;
`;

const StyledLink = styled(Link)`
    color: blue !important;
    text-decoration: underline !important;
`;
