import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

function TimeAgo({ eventTime, justNow }) {
    const [timeAgo, setTimeAgo] = useState('');

    // Helper function to calculate the time ago in a human-readable format
    const formatTimeAgo = (secondsElapsed) => {
        if (secondsElapsed < 60) {
            if (justNow) {
                return `just now`;
            } else {
                return `${secondsElapsed} second${secondsElapsed === 1 ? '' : 's'} ago`;
            }
        } else if (secondsElapsed < 3600) {
            const minutes = Math.floor(secondsElapsed / 60);
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else if (secondsElapsed < 86400) {
            const hours = Math.floor(secondsElapsed / 3600);
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        } else {
            const days = Math.floor(secondsElapsed / 86400);
            return `${days} day${days === 1 ? '' : 's'} ago`;
        }
    };

    // Update the time ago text every second
    useEffect(() => {
        const update = () => {
            const now = Date.now();
            const secondsElapsed = Math.floor((now - new Date(eventTime).getTime()) / 1000);
            setTimeAgo(formatTimeAgo(secondsElapsed));
        };

        update(); // Initial update
        const intervalId = setInterval(update, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [eventTime]);

    return <span>{timeAgo}</span>;
}

export default TimeAgo;

TimeAgo.propTypes = {
    eventTime: propTypes.string.isRequired,
    justNow: propTypes.bool
}

TimeAgo.defaultProps = {
    justNow: false
}
