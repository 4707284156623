import {get, post, patch, del} from './common.js';


export function getTagsAdmin(query_params={}) {
    return get('/tags/', 'admin', query_params=query_params)
};

export function getTagAdmin(tag_id) {
    return get('/tags/' + tag_id + '/', 'admin')
};

export function createTagAdmin(data) {
    return post('/tags/', data, 'admin')
};

export function updateTagAdmin(tag_id, data) {
    return patch('/tags/' + tag_id + '/', data, 'admin')
};

export function deleteTagAdmin(tag_id) {
    return del('/tags/' + tag_id + '/', 'admin')
};
