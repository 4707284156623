import React, { useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { COLORS } from '../../values/colors';
import { CloseButton } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import mixpanel from 'mixpanel-browser';


const VideoListItem = ({ video, handleVideoSelect, isSelected, autoplay}) => {

    const playerRef = useRef(null);

    useEffect(() => {
        if (autoplay) {
            playerRef.current.play();
        }
    }
    ,[]);

    return(
        <div style={{position: 'relative'}}>
        <ItemContainer 
            className="video-list-item" 
            onClick={(e) => {handleVideoSelect(video)}}
            onMouseEnter={(e) => playerRef.current.play()}
            onMouseLeave={(e) => {
                if (!isSelected)
                    playerRef.current.pause()
                }}
            isSelected={isSelected}
            >
            <ItemHeader
                isSelected={isSelected}
            >
                <ThumbnailContainer
                    isSelected={isSelected}
                    >    
                    <VideoThumbnail playsInline muted loop ref={playerRef} preload='true'
                        poster={video.thumbnail}
                        src={video.vidpath}
                        isSelected={isSelected}
                        />
                    { !isSelected &&
                    <PlaySymbol>
                        {`\u23F5`}
                    </PlaySymbol>
                    }
                    {isSelected &&
                    <PositionedCloseButton variant='white'/>
                    }
                </ThumbnailContainer>
                { !isSelected &&
                <VideoByline>
                    <span><h4
                        onClick={(e) => {}}
                    >{video.title}</h4></span>
                    <span>
                        {video.author + '  \u2022  ' + video.date}
                    </span>
                    <span>
                        {video.duration + '  \u2022  ' + video.chapters + ' Chapters'}
                    </span>
                </VideoByline>
                }
                {!isSelected &&
                <ExpandSymbol>
                     {`\u2039`}
                </ExpandSymbol>
                }
            </ItemHeader>
            <Collapse in={isSelected}>
                <div>
                <VideoByline>
                    <span><h4
                        onClick={(e) => {}}
                    >{video.title}</h4></span>
                    <span>
                        {video.author + '  \u2022  ' + video.date}
                    </span>
                    <span>
                        {video.duration + '  \u2022  ' + video.chapters + ' Chapters'}
                    </span>
                </VideoByline>
                </div>
            </Collapse>    
        </ItemContainer>
        <DummyContainer isSelected={isSelected}/>
        </div>
    );
;}

const ItemContainer = styled.div`
    position: ${props => props.isSelected ? 'absolute' : 'static'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #333;
    width: 100%;
    z-index: ${props => props.isSelected ? 20 : 0};

    &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        transform: scale(1.01);
    }
 `;

const DummyContainer = styled.div`
    position: relative;
    display: ${props => props.isSelected ? 'flex' : 'none'};
    flex-direction: column;
    height: 140px;
    justify-content: stretch;
    align-items: stretch;
    padding: 10px;
    border-radius: 5px;
    `;


const ItemHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props => props.isSelected ? '100%' : '120px'};
    min-width: 270px;

    /* h4 {
        &:hover {
            color: ${COLORS.orchid_indigo};
        }
    } */
`;

const ThumbnailContainer = styled.div`
    position: relative;
    display: inline-flex;
    /* flex: 0 0 213; */
    border-radius: 7px;
    overflow: hidden;
    /* height: 120px; */
    height: ${props => props.isSelected ? '100%' : '120px'};
    width: ${props => props.isSelected ? '100%' : 'auto'};
    min-width: 213.33px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    
    &:hover {
        /* opacity: 0.8; */
        div {opacity: 0.7;}
    }
`;

const VideoThumbnail = styled.video`
    position: relative;
    height: ${props => props.isSelected ? 'auto' : '120px'};
    width: ${props => props.isSelected ? '100%' : 'auto'};
    transition: all 200ms ease-in-out;
    z-index: 2;
    opacity: 1;
`;

const PlaySymbol = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease-in-out;
    opacity: 0.8;
    font-size: 50px;
    color: white;
    z-index: 3;
`;

const TagsArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const VideoByline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    font-size: 15px;
    color: #666;
    margin-left: 10px;
    width: 100%;
    text-align: left;
    overflow: hidden;

    h4 {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 3px;
    }
`;

const ExpandSymbol = styled.div`
    align-self: center;
    justify-self: flex-end;
    font-size: 40px;
    color: #666;
    cursor: pointer;
    rotate: 90deg;
    /* transition: all 150ms ease-in-out; */
    padding: 10px;
`;

const VideoDemo = ({videos}) => {

    const [selectedVideo, setSelectedVideo] = useState(null);

    const handleVideoSelect = (video) => {
        
        if (video === selectedVideo) {
            setSelectedVideo(null);
            return;
        }
        setSelectedVideo(video);

        mixpanel.track('Watched Demo Video', {
            'Video Title': video.title,
        });
    }

    return (
        <Container>
        <VideoList>
            {videos.map((video, index) => {
                return <VideoListItem 
                    autoplay = {index === 0}
                    key={index} 
                    video={video} 
                    isSelected={video===selectedVideo}
                    handleVideoSelect={()=>handleVideoSelect(video)}/>
            }
            )}
        </VideoList>
        </Container>
    );
}

export default VideoDemo;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    /* border: 1px solid green; */
`;

const VideoList = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 10px;
    width: 100%;
    /* padding: 10px; */
`;


const PositionedCloseButton = styled(CloseButton)`
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
`;