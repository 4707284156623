import { useState } from 'react';
import { Button } from 'react-bootstrap';


const MicroS3Uploader = ({ presignedUrl }) => {
    
    const [uploadFile, setUploadFile] = useState(null);
    const [status, setStatus] = useState("");
    

    async function pushFileToS3(presignedUrl, uploadFile, contentType) {
        const myHeaders = new Headers({ 'Content-Type': contentType });
        const response = await fetch(presignedUrl, {
            method: 'PUT',
            headers: myHeaders,
            body: uploadFile
        });
        return response;
    }

    return (
        <div>
            <input type="file" onChange={(event) => {
                setUploadFile(event.target.files[0]);
            }} />
            <Button size="sm" onClick={(event) => {
                event.preventDefault();
                if (uploadFile == null) {
                    setStatus("No file selected");
                    return;
                }
                setStatus("Uploading...");
                pushFileToS3(presignedUrl, uploadFile, uploadFile.type).then((response) => {
                    if (response.status == 200) {
                        setStatus("Upload successful!");
                    } else {
                        setStatus("Upload failed!");
                    }
                });
            }}>Upload</Button>
            &nbsp;&nbsp;
            <span>{ status }</span>
        </div>
    )
}

export default MicroS3Uploader;
