export const url_api = 'https://orchid-api-prod.orchidsurgical.com/api'
export const api_version = '/v1'
export const admin_api_version = '/admin'
export const environment = 'prod'
export const gtag_id = 'G-EH0ZNVEM3T'
export const gtag_config = {
}
export const mixpanel_id = '9c58ba55cc215d39f1efb3c57778e1fc'
export const mixpanel_config = 
    {
        debug: false, 
        ignore_dnt: true,
        track_pageview: true, 
        persistence: 'localStorage',
        api_host: 'https://mixpanel.orchidsurgical.com'
    }