import { styled } from "styled-components";
import React, { useState, useEffect, useRef } from 'react';
import { propTypes } from "react-bootstrap/esm/Image.js";
import { Button, FormCheck } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import SymbolBubble from "./SymbolBubble";
import { Spinner } from "react-bootstrap";


const ExportDownload = ({exportData, handleExport, attachment}) => {

    const [withoutWatermark, setWithoutWatermark] = useState(false);

    const handleExportClick = (e) => {
        e.currentTarget.disabled=true;
        e.currentTarget.innerText = "Export Requested...";
        handleExport(attachment.id, withoutWatermark);
        try{
            mixpanel.track("Export Requested", {
                "Attachment ID": attachment.id,
                "Source": "Export Modal"
            });
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    const getExportTimeEstimate = (attachment, startTime) => {
        let hours = 24;
        if (attachment && attachment.user_metadata && attachment.user_metadata.duration) {
            hours = attachment.user_metadata.duration / 60 / 60;
        }

        if (startTime) {
            let now = new Date();
            startTime = new Date(startTime + "Z");
            let diff = now - startTime;
            let diffHours = diff / 1000 / 60 / 60;
            hours = hours - diffHours;
        }

        if (hours <= 1) {
            return "less than an hour";
        } else {
            return Math.ceil(hours).toString() + " hours";
        }

    }

    if (!attachment) {
        return (
            <Spinner animation="border" role="status" />
        )
    }

    if (!exportData) {
        return (
            <p>
            To export this case as an MP4 video, click the button below. We'll
            send you an email when the export is ready to download, estimated
            at <b>{getExportTimeEstimate(attachment, null)}</b>. <br /><br />
            { attachment.permissions.can_export_without_watermark &&
                <>
                <FormCheck type="checkbox" checked={!withoutWatermark} label="Include watermark? (Admins only)" onChange={(e) => {setWithoutWatermark(!e.target.checked)}}/>
                </>
            }
            <Button onClick={handleExportClick}>Export</Button>
          </p>
        );
    }

    if (exportData.status === 'pending' || exportData.status === 'processing') {
        return (
            <p>
                Your export has been requested and is currently {exportData.status}.
                We'll send you an email when it's ready to download, currently estimated
                at <b>{getExportTimeEstimate(attachment, exportData.created_on)}</b>.<br />
            </p>
        )
    }

    if (exportData.status === 'ready' && exportData.download_link) {
        try{
            mixpanel.track("Export Download Started", {
                "Attachment ID": attachment.id,
                "Source": "Export Modal"
            });
        } catch (e) {
            Sentry.captureException(e);
        }
        return (
            <p>
                Your export is ready to download. Click the link below to download the file.<br /><br />
                <Button href={exportData.download_link} target="_blank" download variant="outline-info">
                    <img src="/images/icons/download.svg" width="16px" />&nbsp;
                    Download Export
                </Button>
            </p>
        )
    }
}

export default ExportDownload;

const DownloadLink = styled.a`
    color: #007bff;
    text-decoration: underline;
`;
