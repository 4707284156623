import {get, post, patch, del} from './common.js';


// Organizations
export function getOrganizationsAdmin(query_params={}) {
    return get('/organizations/', 'admin', query_params=query_params)
}

export function getOrganizationAdmin(organization_id) {
    return get('/organizations/' + organization_id + '/', 'admin')
}

export function createOrganizationAdmin(data) {
    return post('/organizations/', data, 'admin')
}

export function updateOrganizationAdmin(organization_id, data) {
    return patch('/organizations/' + organization_id + '/', data, 'admin')
}

export function deleteOrganizationAdmin(organization_id) {
    return del('/organizations/' + organization_id + '/', 'admin')
}

// Organization Users
export function getOrganizationUsersAdmin(query_params={}) {
    return get('/organization_users/', 'admin', query_params=query_params)
}

export function getOrganizationUserAdmin(organization_user_id) {
    return get('/organization_users/' + organization_user_id + '/', 'admin')
}

export function createOrganizationUserAdmin(data) {
    return post('/organization_users/', data, 'admin')
}

export function updateOrganizationUserAdmin(organization_user_id, data) {
    return patch('/organization_users/' + organization_user_id + '/', data, 'admin')
}

export function deleteOrganizationUserAdmin(organization_user_id) {
    return del('/organization_users/' + organization_user_id + '/', 'admin')
}
