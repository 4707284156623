export const specialization_enum = [
    {
        "value": "general",
        "label": "General",
    },
    {
        "value": "orthopedic",
        "label": "Orthopedic",
    },
    {
        "value": "cardiothoracic",
        "label": "Cardiothoracic",
    },
    {
        "value": "neuro",
        "label": "Neurosurgery",
    },
    {
        "value": "plastic",
        "label": "Plastic",
    },
    {
        "value": "vascular",
        "label": "Vascular",
    },
    {
        "value": "pediatric",
        "label": "Pediatric",
    },
    {
        "value": "colorectal",
        "label": "Colorectal",
    },
    {
        "value": "urology",
        "label": "Urology",
    },
    {
        "value": "ophthalmology",
        "label": "Ophthalmology",
    },
    {
        "value": "otolaryngology",
        "label": "Otolaryngology",
    },
    {
        "value": "gynecologic",
        "label": "Gynecologic",
    },
    {
        "value": "oral_maxillofacial",
        "label": "Oral and Maxillofacial",
    },
    {
        "value": "transplant",
        "label": "Transplant",
    },
    {
        "value": "bariatric",
        "label": "Bariatric",
    },
    {
        "value": "trauma",
        "label": "Trauma",
    },
    {
        "value": "hand",
        "label": "Hand",
    },
    {
        "value": "oncology",
        "label": "Oncology",
    },
    {
        "value": "thoracic",
        "label": "Thoracic",
    },
    {
        "value": "hepatobiliary",
        "label": "Hepatobiliary",
    }
];