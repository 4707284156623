import { useState, useEffect } from 'react';
import { getGroupMembersAdmin, deleteGroupMemberAdmin, createGroupMemberAdmin } from '../services/api/admin_groups.js';
import { useParams } from 'react-router-dom';
import ListView from './ListView.js';
import NewEntry from './NewEntry.js';
import styled from 'styled-components';


const AdminGroupMembers = (props) => {

    const { group_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false,
            "filterable": true,
        },
        {
            "key": "user_id",
            "label": "User ID",
            "type": "copy",
            "settable": true,
            "filterable": true,
        },
        {
            "key": "user.email",
            "label": "Email",
            "type": "string",
            "settable": false,
            "filterable": false,
            "detailLink": "/admin/groups/<group_id>/members/<id>",
        },
        {
            "key": "is_admin",
            "label": "Admin?",
            "type": "boolean",
            "settable": true,
            "filterable": true,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": false,
            "filterable": true,
        }
    ];

    return (
        <Page>
            <ListView
                title="Admin: Group Members"
                attributes={attributes}
                list_method={getGroupMembersAdmin}
                list_query_params={{'group_id': group_id}}
                delete_method={deleteGroupMemberAdmin}
                paginate={true}
                max_per_page={50} />
            <NewEntry
                title="Admin: New Member"
                attributes={attributes}
                create_method={createGroupMemberAdmin}
                base_data={{'group_id': group_id}} />
        </Page>
    );
}

export default AdminGroupMembers;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
