import { url_api } from "../../sources";
import { get, patch, del, post} from "./common.js";


export function getPublicKey() {
    return get('/stripe/public_key/');
}


export function getCheckoutSession(user_id) {
    return post('/stripe/checkout/', {user_id: user_id});
}


export function getSubscription() {
    return get('/me/subscription/');
}


export function cancelSubscription() {
    return del('/me/subscription/');
}


export function reactivateSubscription() {
    return patch('/me/subscription/');
}
