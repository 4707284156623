import {get, post, patch, del, paramsToString} from './common.js';

//get all groups
export function getGroups(query_params={}) {
    return get('/groups/', 'api', query_params);
}

//get group by id
export function getGroup(id) {
    return get(`/groups/${id}/`);
}

//create group
export function createGroup(data) {
    return post('/groups/', data);
}

//delete group by id
export function deleteGroup(id) {
    return del(`/groups/${id}/`);
}

//update group by id
export function updateGroup(id, data) {
    return patch(`/groups/${id}/`, data);
}

//get shared attachments by group id
export function getSharedAttachments(id) {
    return get(`/groups/${id}/attachment_shares/`);
}

//get shared attachment by group id and attachment id
export function getSharedAttachment(group_id, attachment_id) {
    return get(`/groups/${group_id}/attachment_shares/${attachment_id}/`);
}

//get group members by group id
export function getGroupMembers(id) {
    return get(`/groups/${id}/members/`);
}

//get group member by group id and member id
export function getGroupMember(group_id, member_id) {
    return get(`/groups/${group_id}/members/${member_id}/`);
}

//add member to group by group id
export function addGroupMember(id, data) {
    return post(`/groups/${id}/members/`, data);
}

//remove member from group by group id and member id
export function removeGroupMember(group_id, member_id) {
    return del(`/groups/${group_id}/members/${member_id}/`);
}

//update a group member by group id and member id
export function patchGroupMember(group_id, member_id, data) {
    return patch(`/groups/${group_id}/members/${member_id}/`, data);
}

//get group messages by group id
export function getGroupMessages(id, params) {
    return get(`/groups/${id}/messages/${paramsToString(params)}`);
}

//send message to group by group id
export function sendMessage(id, data) {
    return post(`/groups/${id}/messages/`, data);
}

//edit message by group id and message id
export function editMessage(group_id, message_id, data) {
    return patch(`/groups/${group_id}/messages/${message_id}/`, data);
}

//delete message by group id and message id
export function deleteMessage(group_id, message_id) {
    return del(`/groups/${group_id}/messages/${message_id}/`);
}

