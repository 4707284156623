import { get, post } from "./common.js";

export function getDMs (query_params={}) {
    return get('/dms/', 'api', query_params);
}

export function createDM (user_ids) {
    return post('/dms/', { user_ids });
}

export function getDM (dm_id) {
    return get(`/dms/${dm_id}/`);
}