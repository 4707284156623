export const tag_type_enum = [
    {
        "value": "general",
        "label": "General",
    },
    {
        "value": "procedure",
        "label": "Procedure",
    },
    {
        "value": "intervention",
        "label": "Intervention",
    },
    {
        "value": "laterality",
        "label": "Laterality",
    },
    {
        "value": "complication",
        "label": "Complication",
    }
];