import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { getGroupMemberAdmin, deleteGroupMemberAdmin, updateGroupMemberAdmin } from '../services/api/admin_groups.js';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import ListView from './ListView.js';
import NewEntry from './NewEntry.js';


const AdminGroupMember = (props) => {

    const { group_id } = useParams();
    const { member_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "user_id",
            "label": "User ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "user.email",
            "label": "Email",
            "type": "string",
            "settable": false,
        },
        {
            "key": "group_id",
            "label": "Group ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "group.name",
            "label": "Group Name",
            "type": "string",
            "settable": false,
        },
        {
            "key": "is_admin",
            "label": "Admin?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": true,
        }
    ]

    return (
        <Page>
            <DetailView
                title="Admin: Group Member"
                get_method={getGroupMemberAdmin}
                update_method={updateGroupMemberAdmin}
                delete_method={deleteGroupMemberAdmin}
                attributes={attributes}
                primary_key={member_id} />
        </Page>
    );
}

export default AdminGroupMember;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
