import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        uploadIsOpen: false,
    },
    reducers: {
        setUploadIsOpen(state, action){
            state.uploadIsOpen = action.payload;
        },
        
    },
});

export const { setUploadIsOpen} = uiSlice.actions;

export default uiSlice.reducer;