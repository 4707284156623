import { get, post, del} from './common.js';

export function joinWaitlist(data) {
    return post('/waitlist/', data);
}

export function getWaitlist() {
    return get('/waitlist/');
}

export function leaveWaitlist(id) {
    return del('/waitlist/' + id + '/');
}