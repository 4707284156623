import {useEffect, useState, useRef } from 'react';
import { Outlet } from "react-router-dom";   
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import useWindowSize from '../hooks/useWindowSize';
import UploadModal from './UploadModal';
import { setUploadIsOpen } from '../services/redux/uiSlice';
import NotificationsWidget from './NotificationsWidget';


const AppLayout = ({children}) => {

    const windowSize = useWindowSize();
    const dispatch = useDispatch();
    const uploadIsOpen = useSelector( state => state.ui.uploadIsOpen);

    return (
        <>
        {windowSize.width > 900 ?
        <StyledAppLayout >
            <Sidebar />
            <div className="content">
                <Outlet/>
            </div>
         </StyledAppLayout>
        :
        <StyledAppLayout >
            <div className="content">
                <Outlet/>
            </div>
            <Sidebar />
        </StyledAppLayout>
        }
        <UploadModal 
            show={uploadIsOpen}
            onHide={() => {dispatch(setUploadIsOpen(false))}}
        />
        <NotificationsWidget />
        </>
    );

}

export default AppLayout;

const StyledAppLayout = styled.div`

    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    max-height: 100vh;

    @media (max-width: 899px) {
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        @media (max-width: 899px) {
            height: calc(100vh - 60px);
        }
    }
`;