import styled from 'styled-components';
import { searchUsers } from '../services/api/search';
import mixpanel from 'mixpanel-browser';
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { CloseButton } from 'react-bootstrap';
import UserIcon from './UserIcon';


const CaseShareRecipientSelect = ({groups, selectedRecipients, setSelectedRecipients}) => {

    const [ term, setTerm ] = useState('');
    const [ results, setResults ] = useState([]);
    const [ searchLoading, setSearchLoading ] = useState(false);
    const [ displayedGroups, setDisplayedGroups ] = useState(groups);
    const [ searchFocused, setSearchFocused ] = useState(false);
    const [ invite, setInvite ] = useState('');

    function filterGroups(groups, term) {
        return groups.filter((group) => group.name.toLowerCase().includes(term.toLowerCase()));
    }

    function findUsers(term) {
        //don't search if the term is less than 3 characters
        //only search if the user is not still typing
        //while searching, set searchLoading to true if the search takes longer than 300ms
        //set searchLoading to false when the search is done

        if (term.length < 3) {
            setDisplayedGroups(groups);
            setResults([]);
            return;
        }

        //filter groups
        setDisplayedGroups(filterGroups(groups, term));

        //search for users
        setSearchLoading(true);
        searchUsers({term: term})
        .then((users) => {
            if (users.length == 0 && isEmail(term))
                setInvite(term);
            else
                setInvite('');
            setResults(users.filter((user) => !selectedRecipients.map((r) => r.id).includes(user.id)));
            setSearchLoading(false);
        });

    }

    function isGroup (recipient) {
        // console.log('checking if group', recipient, recipient?.membership != null);
        return recipient?.membership != null;
    }

    function isUser (recipient) {
        // console.log('checking if user', recipient, recipient?.last_name != null);
        return recipient?.last_name != null;
    }

    function isInvite (recipient) {
        return recipient?.invite_address != null;
    }

    function isEmail(text) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/;
        // Test the email against the regular expression
        if (!emailRegex.test(text))
            return false;

        // Additional validation: ensure the domain part has at least one dot
        const domainPart = text.split('@')[1];
        if (!domainPart || domainPart.indexOf('.') === -1)
            return false;

        // Everything is valid, return true
        return true;
    }


    function showSearchBar() {
        if (selectedRecipients.length == 0) {
            return true;
        } else if (selectedRecipients.length < 4 && (isUser(selectedRecipients[0]) || isInvite(selectedRecipients[0]))) {
            return true;
        } else {
            return false;
        }
    }

    function showGroupResults() {
        if (selectedRecipients.length > 0 && isUser(selectedRecipients[0])) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <>
        <SearchBarContainer>
            <div>
                {selectedRecipients.length == 1 && isGroup(selectedRecipients[0]) &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '0px',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <img 
                            src={selectedRecipients[0].avatar_url} 
                            alt='logo' 
                            style={{maxWidth:'50px', maxHeight:'26px', height:'auto', width:'auto'}}
                            onError={(e) => {e.target.onerror=null; e.target.src='/images/icons/orchid.svg';}}
                            /> {selectedRecipients[0].name}
                        <CloseButton
                            onClick={() => {
                                setSelectedRecipients([]);
                            }}
                        />
                    </div>
                }
                {selectedRecipients.length > 0 && (isUser(selectedRecipients[0]) || isInvite(selectedRecipients[0])) &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '0px',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            justifyContent: 'space-between'
                    }}
                    >
                    {selectedRecipients.map((recipient, index) => {
                        if (isInvite(recipient))
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: '0px',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        marginBlockStart: '0.25em',
                                    }}
                                >
                                    <img 
                                        src='/images/icons/mail.svg'
                                        alt='Invite'
                                        style={{maxWidth:'50px', maxHeight:'26px', height:'auto', width:'auto'}}
                                    />
                                    <div
                                        style={{
                                            display: 'flex', alignItems: 'end', gap: '5px'
                                        }}
                                        >
                                        {recipient.invite_address}
                                    </div>
                                    <CloseButton
                                        onClick={() => {
                                            setSelectedRecipients(selectedRecipients.filter((r) => r != recipient));
                                        }}
                                    />
                                </div>
                            )
                        else
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '0px',
                                    whiteSpace: 'nowrap',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    marginBlockStart: '0.25em',
                                }}
                            >
                                <UserIcon 
                                    user={recipient}
                                    size={26}
                                />
                                <div
                                    style={{
                                        display: 'flex', alignItems: 'end', gap: '5px'
                                    }}
                                    >
                                    {(recipient.first_name && recipient.last_name) ?
                                        <span>{recipient.first_name} {recipient.last_name} &nbsp;
                                        {recipient.company &&
                                        <div
                                            style={{display: 'inline-block', color: 'gray', fontSize:'12px'}}
                                            >
                                            {recipient.company}
                                        </div>
                                        }</span> :
                                        <span>{recipient.email}</span>
                                    }
                                </div>
                                <CloseButton
                                    onClick={() => {
                                        setSelectedRecipients(selectedRecipients.filter((r) => r.id != recipient.id));
                                    }}
                                    />
                            </div>
                        )
                    })}
                    </div>
                }
            </div>
            {showSearchBar() &&
            <div
                style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', paddingTop: `${selectedRecipients.length > 0 ? '5px' : '0px'}`}}
            >
            <SearchBar 
                placeholder={selectedRecipients.length > 0? "Search for another user..." : "Search for a user or group..."}
                value={term}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => 
                    setTimeout(() => {
                        setSearchFocused(false)}
                    , 200)}
                onChange={(e) => {
                    setTerm(e.target.value);
                    findUsers(e.target.value);
                }}
            />
            {searchLoading && <Spinner animation="border" size="sm" variant="secondary"/>}
            </div>
            }
        </SearchBarContainer>
        <SearchResultsContainer
            $show={searchFocused && ((selectedRecipients.length == 0 && displayedGroups.length > 0) || results.length > 0 || invite.length > 0)}
        >
            <SearchResults>
                {selectedRecipients.length == 0 && displayedGroups.length > 0 &&
                <div
                    style={{
                        fontSize: '0.8em',
                        padding: '5px 10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid #ccc',
                        cursor: 'default'
                    }}
                >
                    Groups
                </div>}
                {selectedRecipients.length == 0 && displayedGroups.map((group, index) => {
                    return (
                        <SearchResult
                            onClick={() => {
                                console.log('adding group', group);
                                setSelectedRecipients([...selectedRecipients, group]);
                                setTerm('');
                                setDisplayedGroups(groups);
                                setResults([]);
                            }}
                        >
                            <img 
                                src={group.avatar_url} 
                                alt='logo' 
                                style={{maxWidth:'50px', maxHeight:'26px', height:'auto', width:'auto'}}
                                onError={(e) => {e.target.onerror=null; e.target.src='/images/icons/orchid.svg';}}
                                /> {group.name}
                        </SearchResult>
                    )
                })
                }
                <div
                    style={{
                        fontSize: '0.8em',
                        padding: '5px 10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid #ccc',
                        borderTop: '1px solid #ccc',
                        cursor: 'default'
                    }}
                >
                    {invite.length ==0 ? 'Users' : 'Invite a user by email'}
                </div>
                {results.length == 0 && invite ==0 && <span> &nbsp; Start typing a name to search for users, or add an email to send an invite.</span>}
                {results.map((user, index) => {
                    return (
                        <SearchResult
                            onClick={() => {
                                console.log('adding user', user);
                                setSelectedRecipients([...selectedRecipients, user]);
                                setTerm('');
                                setDisplayedGroups(groups);
                                setResults([]);
                                setInvite('');
                            }}>
                            <div
                                    style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', alignItems: 'center', gap:'5px'}}
                                >
                                <UserIcon 
                                    user={user}
                                    size={26}
                                />
                                <div
                                    style={{
                                        display: 'flex', alignItems: 'end', gap: '5px'
                                    }}
                                    >
                                    {user.first_name.length>0 && user.last_name.length>0 ?
                                    <span>{user.first_name} {user.last_name}</span> :
                                    <span>{user?.email}</span>
                                    }   
                                    {user.company &&
                                    <div
                                        style={{display: 'inline-block', color: 'gray', fontSize:'12px'}}
                                        >
                                        {user.company}
                                    </div>
                                    }
                                </div>
                            </div>
                        </SearchResult>
                    )
                })}
                {invite.length > 0 &&
                    <SearchResult
                    onClick={() => {
                        console.log('adding email', invite);
                        setSelectedRecipients([...selectedRecipients, {invite_address: invite}]);
                        setTerm('');
                        setDisplayedGroups(groups);
                        setResults([]);
                        setInvite('');
                    }}>
                    <div
                            style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', alignItems: 'center', gap:'5px'}}
                        >
                        <img 
                            src='/images/icons/mail.svg'
                            alt='Invite'
                            style={{maxWidth:'50px', maxHeight:'26px', height:'auto', width:'auto'}}
                        />
                        <div
                            style={{
                                display: 'flex', alignItems: 'end', gap: '5px'
                            }}
                            >
                            {invite}
                        </div>
                    </div>
                </SearchResult>}
            </SearchResults>
        </SearchResultsContainer>
        </>
    );

}

const SearchBarContainer = styled.div`
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    display: flex;    
`;

const SearchBar = styled.input`
    width: 100%;
    border: none;
    outline: none;
`;

const SearchResultsContainer = styled.div`
    position: absolute;
    flex-direction: column;
    width: calc(100% - 32px);
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: auto;
    display: ${props => props.$show ? 'block' : 'none'};
`;

const SearchResults = styled.ul`
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    overflow: auto;
    background-color: white;
`;

const SearchResult = styled.li`
    cursor: pointer;
    padding: 5px 10px;
    margin: 0px;
    list-style-type: none;
    
    &:hover {
        background-color: #f0f0f0;
    }
`;

const GroupCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
`;

const UserCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
`;

export default CaseShareRecipientSelect;