export const prefix_enum = [
    {
        "value": "Mr.",
        "label": "Mr.",
    },
    {
        "value": "Mrs.",
        "label": "Mrs.",
    },
    {
        "value": "Ms.",
        "label": "Ms.",
    },
    {
        "value": "Dr.",
        "label": "Dr.",
    },
];
