import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { getGroupAdmin, deleteGroupAdmin, updateGroupAdmin, getGroupMembersAdmin, deleteGroupMemberAdmin, createGroupMemberAdmin } from '../services/api/admin_groups.js';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import ListView from './ListView.js';
import NewEntry from './NewEntry.js';


const AdminGroup = (props) => {

    const { group_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "owner_id",
            "label": "Owner ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "name",
            "label": "Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "description",
            "label": "Description",
            "type": "text",
            "settable": true,
        },
        {
            "key": "is_public",
            "label": "Public?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "settable": false,
        },
        {
            "key": "updated_on",
            "label": "Updated At",
            "type": "string",
            "settable": false,
        },
        {
            "key": "is_care_group",
            "label": "Care Group?",
            "type": "boolean",
            "settable": true,
            "tooltip": {
                "variant": "warning",
                "text": "Care Groups only allow members with the same Email domain as the group's owner.",
            }
        },
        {
            "key": "allow_phi",
            "label": "Allow PHI?",
            "type": "boolean",
            "settable": true,
            "tooltip": {
                "variant": "warning",
                "text": "If checked, attachments marked as containing PHI can be shared with this group.",
            }
        },
        {
            "key": "require_patient_consent",
            "label": "Require Patient Consent for PHI?",
            "type": "boolean",
            "settable": true,
            "tooltip": {
                "variant": "warning",
                "text": "If Allow PHI and Require Patient Consent are both checked, attachments marked as containing PHI can only be shared with this group if the patient has consented to sharing their PHI.",
            }
        },
        {
            "key": "avatar_url",
            "label": "Download Avatar",
            "type": "external_link",
            "settable": false,
        },
        {
            "key": "avatar_upload_url",
            "label": "Upload Avatar",
            "type": "upload",
            "settable": false,
        },
        {
            "label": "Members",
            "type": "link",
            "detailLink": "/admin/groups/<id>/members",
            "settable": false,
        }
    ]

    return (
        <Page>
            <DetailView
                title="Admin: Group"
                get_method={getGroupAdmin}
                update_method={updateGroupAdmin}
                delete_method={deleteGroupAdmin}
                attributes={attributes}
                primary_key={group_id} />
        </Page>
    );
}

export default AdminGroup;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
