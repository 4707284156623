import { get, post, patch, del, paramsToString } from './common.js';

//get all agreements to which the user has access
export function getAgreements(params) {
    return get(`/agreements/${paramsToString(params)}`);
}

//get agreement by id
export function getAgreement(id) {
    return get(`/agreements/${id}/`);
}

//list accepted agreements
export function getAcceptedAgreements() {
    return get(`/agreements/accepted/`);
}

//list outstanding agreements
export function getOutstandingAgreements() {
    return get(`/agreements/outstanding/`);
}

//accept agreement by version id
export function acceptAgreement(id) {
    return post(`/agreements/outstanding/`, {'agreement_version_id': id});
}