import { get, patch, del, post} from "./common.js";

export function getMe() {
    return get('/me/');
}

export function updatePassword(old_password, password) {
    return patch('/me/', {password: password, old_password: old_password});
}

export function updateUser(data) {
    return patch('/me/', data);
}

// MFA
export function requestMFA() {
    return post('/me/mfa/');
}

export function verifyMFA(code) {
    return patch('/me/mfa/', {otp_code: code});
}

export function disableMFA() {
    return del('/me/mfa/');
}
