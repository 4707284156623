import styled from 'styled-components';
import { useState, useEffect } from 'react';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import { getTagAdmin, updateTagAdmin, deleteTagAdmin } from '../services/api/admin_tags.js';
import { specialization_enum } from '../enums/specialization.js';
import { tag_type_enum } from '../enums/tag_type.js';


const AdminTag = (props) => {

    const { tag_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "tag_name",
            "label": "Tag Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "tag_type",
            "label": "Tag Type",
            "type": "choice",
            "settable": true,
            "choices": tag_type_enum,
        },
        {
            "key": "tag_specialization",
            "label": "Tag Specialization",
            "type": "choice",
            "settable": true,
            "choices": specialization_enum
        },
        {
            "key": "tag_description",
            "label": "Tag Description",
            "type": "text",
            "settable": true,
        },
        {
            "key": "is_public",
            "label": "Public?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "owner_id",
            "label": "Owner ID",
            "type": "string",
            "settable": true,
        }
    ]

    return (
        <Page>
            <DetailView
                title="Admin: Tag"
                primary_key={tag_id}
                get_method={getTagAdmin}
                update_method={updateTagAdmin}
                delete_method={deleteTagAdmin}
                attributes={attributes}
            />
        </Page>
    )

};

export default AdminTag;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
