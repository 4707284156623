import React, { useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';




const TagsArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;



const MobileVideoDemo = ({videos}) => {

    const playerRefs = [useRef(null), useRef(null), useRef(null)]
    const [selectedVideo, setSelectedVideo] = useState(0);

    const handleVideoClick = (index) => {
        setSelectedVideo(index);
        mixpanel.track('Watched Demo Video', {
            'Video Title': videos[index].title,
        });
    }

    useEffect(() => {
        playerRefs.forEach((ref, i) => {
            if(i !== selectedVideo) {
                ref.current.pause();
            } else {
                ref.current.play();
            }
        });
    }
    , [selectedVideo]);

    return (
        <Container>
                <div>
                { videos.map((video, index) => {
                    return(
                    <VideoPlayer 
                        isActive={index==selectedVideo}
                        ref={playerRefs[index]} 
                        src={video.vidpath} 
                        poster={video.thumbnail}
                        autobuffer muted playsInline loop
                        />
                )}
                )}
                </div>
                <VideoByline>
                    <span><h4>{videos[selectedVideo].title}</h4></span>
                    <span>{videos[selectedVideo].author + '  \u2022  ' + videos[selectedVideo].date}</span>
                    <span>{videos[selectedVideo].duration + '  \u2022  ' + videos[selectedVideo].chapters + ' Chapters'}</span>
                </VideoByline>
            <Carousel>
                {videos.map((video, index) => { 
                    return (
                        <CarouselIcon 
                            key={index} 
                            src={video.thumbnail} 
                            isActive={index==selectedVideo}
                            onClick={()=>handleVideoClick(index)}
                            />
                        )
                    }
                )}
            </Carousel>
        </Container>
    );
}

export default MobileVideoDemo;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: auto;
    align-items: center;
    justify-content: center;
    gap: 30px;
`;

const VideoPlayer = styled.video`
    position: relative;
    display: ${props => props.isActive ? "flex" : "none"};
    width: 100%;
    height: auto;
    flex: 1 1 auto;
    max-width: 500px;
    z-index: 1;
    border-radius: 10px;
    left: -10px;

    @media (max-width: 500px) {
        border-radius: 0px;
    }

`;

const VideoByline = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: white;
    width: 250px;
    text-align: left;
    z-index: 2;
    margin: 0;
    bottom: 15%;
    left: calc(50% - 125px);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @media (max-width: 500px) {
        left: 125px;
        bottom: 20%;
    }

    h4 {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 3px;

    }
`;

const Carousel = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin: 0;
    left: -10px;
`;

const CarouselIcon = styled.img`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100px;
    transform: ${props => props.isActive ? "scale(1.15)" : ""};
    filter: ${props => props.isActive ? "" : "brightness(0.8)"};
    height: auto;
    overflow: hidden;
    margin: 0;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: ${props => props.isActive ? "" : "scale(1.10)"};
        filter: ${props => props.isActive ? "" : "brightness(1)"};
    }
`;