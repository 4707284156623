import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { dateFromTimestamp } from '../services/util/metadata.js';
import { Link, useNavigate } from 'react-router-dom';
import { COLORS } from '../values/colors.js';
import { reduceString, hmsTextFromSeconds } from '../services/util/metadata.js';
import { getThumbnailUrl } from '../services/api/attachments.js';
import { useSelector } from 'react-redux';

const VideoListItem = ({ video, handleVideoSelect, selectedVideo }) => {

    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const navigate = useNavigate();
    const user = useSelector(state => state.user.value);
    const percentWatched = video.user_view / video.user_metadata.duration;
    
    useEffect(() => {
        setVideoThumbnail(getThumbnailUrl(video));
    }, [video]);

    return(
        <ItemContainer 
            className="video-list-item" 
            // onClick={(e) => {handleVideoSelect(video)}}
            onClick={(e) => {
                if (video.status===3) {
                    navigate(`/viewer/${video.id}`);
                } else {
                    e.stopPropagation();
                }
            }}
        >
            <ItemHeader>
                    <ThumbnailContainer
                        onClick={(e) => {if (video.status===3) {navigate(`/viewer/${video.id}`);} else {e.stopPropagation();}}}
                    >    
                        <Thumbnail 
                            src={videoThumbnail} 
                            alt={video.case.case_name} 
                        />
                        {video.status != 3 ? (
                        <StatusContainer>
                            <span>Processing</span>
                        </StatusContainer>
                        ) : (
                        <PlaySymbol>
                            {`\u23F5`}
                        </PlaySymbol>
                        )} 
                         {percentWatched > 0 &&
                            <AlreadyWatchedBar 
                                style={{width: `${percentWatched*100}%`}}
                            />
                        }
                    </ThumbnailContainer>
                <VideoByline>
                    <h4>{video?.subtitle ? video.subtitle : video.case.case_name}</h4>
                    <span>
                        {(video.case.owner.id === user.id) ? 'You' : (
                            video.case.owner.first_name + ' ' + 
                            video.case.owner.last_name)} 
                        {'  \u2022  ' + 
                            dateFromTimestamp(video.created_on)
                        }
                    </span>
                    <span>
                        <span>{hmsTextFromSeconds(video?.user_metadata?.duration, 'hm')}</span>
                        <span>
                            {(video.markers?.length > 0) && 
                                (`  \u2022  ` + video.markers?.length + 
                                ` chapter` + (video.markers?.length>1 ? `s` : ``))
                            }
                        </span>
                    </span>
                </VideoByline>
            </ItemHeader>
            <DetailsContainer className="video-list-item-details">
                <span>{video.case.description}</span>
            </DetailsContainer>
        </ItemContainer>
    );
;}

const ItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #333;
    flex: 1 1 400px;
    max-height: 212px;
    &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        transform: scale(1.01);
    }
 `;

const ItemHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    height: 120px;
`;

const ThumbnailContainer = styled.div`
    display: inline-block;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 120px;
    min-width: 213.33px;
    
    &:hover {
        opacity: 0.8;
        div {opacity: 0.7;}
    }
`;

const Thumbnail = styled.img`
    height: 120px;
    width: auto;
    border-radius: 5px;
`;

const PlaySymbol = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    font-size: 50px;
    color: white;
    z-index: 1;
`;

const StatusContainer = styled.div`
    display: inline-block;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 600;
    color: white;
    border-radius: 10px;
    padding: 1px 5px;
    z-index: 1;
    background-color: ${COLORS.orchid_dark};
`;

const VideoByline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    font-size: 15px;
    color: #666;
    margin-left: 10px;
    width: 100%;
    text-align: left;

    h4 {
        max-height: 2.5em;
        margin-bottom: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const DetailsContainer = styled.div`
    margin: 0;
    padding: 0;
    margin-top: 5px;
    text-align: left;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 3px;
`;

const AlreadyWatchedBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: ${COLORS.orchid_dark};
`;

const VideoGrid = ({ attachments, title, setRequestNextPage }) => {

    const mounted = useRef(true);
    const [displayedVideos, setDisplayedVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [searchTerms, setSearchTerms] = useState('');
    const listRef = useRef(null);

    useEffect(() => {
        setDisplayedVideos(attachments);
    }, [attachments]);

    useEffect(() => {
        if (mounted.current) {
            //set a scroll listener to request the next page of videos
            //when the user scrolls to the bottom of the list
            const handleScroll = (e) => {
                //is scroll at the bototm?
                const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
                if (bottom) {
                    setRequestNextPage(() => true);
                }
            }
            console.log('adding scorll listener')
            listRef.current.addEventListener('scroll', handleScroll);
        }
        return () => mounted.current = false;
    }, []);

    const handleVideoSelect = (video) => {
        setSelectedVideo(video);
    }

    const handleSearch = (e) => {
        setSearchTerms(e.target.value);
        if (e.target.value === '') {
            setDisplayedVideos(attachments);
        } else {
            const words = e.target.value.split(' ');
            const filteredVideos = attachments.filter((video) => {
                let match = Array(words.length).fill(false);
                words.forEach((word, idx) => {
                    if (video.case.case_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.description.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.owner.first_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.owner.last_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.created_on.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.markers.length > 0) {
                        video.markers.forEach((marker) => {
                            if (marker.name.toLowerCase().includes(word.toLowerCase())) {
                                match[idx] = true;
                            }
                            if (marker.description.toLowerCase().includes(word.toLowerCase())) {
                                match[idx] = true;
                            }
                        });
                    }
                });
                return match.every(value => value === true);
            });
            setDisplayedVideos(filteredVideos);
        }
    }

    return ( 
        <VideoListContainer className='video-list'>
            <VideoListHeader>
                <h3>{title}</h3>
                <VideoListCount>{displayedVideos.length} videos</VideoListCount>
            </VideoListHeader>
            <SearchBar>
                <input 
                    type="text" 
                    placeholder="Search videos..." 
                    value={searchTerms}
                    onChange={handleSearch}
                />
            </SearchBar>
            <VideoItemList ref={listRef}>
                {displayedVideos.map((video, index) => (
                    <VideoListItem 
                        key={index} 
                        video={video} 
                        handleVideoSelect={handleVideoSelect}
                        selectedVideo={selectedVideo}
                    />
                ))}
                {attachments.length === 0 && 
                <NoVideosMessage>
                    No videos to display yet... upload some, or join a group!
                </NoVideosMessage>}
            </VideoItemList>
        </VideoListContainer>
     );
}

const VideoListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow: visible;
`;

const SearchBar = styled.div`
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: stretch;
    width: inherit;
    margin: 10px 0;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    /* margin-left: 10px;
    margin-right: auto; */

    input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        color: #333;
    }
`;

const VideoListHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    h3 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 600;
    }
`;

const VideoListCount = styled.div`
    margin-left: 10px;
    font-size: 15px;
    color: #666;
`;

const VideoItemList = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    gap: 20px;

    padding: 5px 10px 10px 10px;
    /* background-image: URL('/images/logo_nobkg_a70.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;

    @media (max-width: 2600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1140px) {
        grid-template-columns: repeat(1, 1fr);
    }
    
`;

const NoVideosMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 15px;
    width: 100%;
    height: 100%;
`;

export default VideoGrid;