import { React } from "react";
import styled from "styled-components";
import Button from "./Button";
import Spinner from 'react-bootstrap/Spinner';

const TextInput = ({placeholder, text, buttonColor, buttonTextColor, onChange, onSubmit, width, maxWidth, name, waiting}) => {
    return (
        <StyledContainer width={width} maxWidth={maxWidth}>
            <StyledTextInput 
                name={name}
                placeholder={placeholder} 
                onChange={onChange}
                type='email'
                required
                >
            </StyledTextInput>
            {(waiting) ? <div style={{display: 'flex', height: '100%', alignItems: 'center'}}><Spinner animation="border" variant="secondary"/> </div> :
            <Button text={text} color={buttonColor} textColor={buttonTextColor} onClick={onSubmit}/>
            }
        </StyledContainer>
    );
}

export default TextInput;

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e1e1;
    padding: 3px 3px 3px 20px;
    border-radius: 50px;
    background-color: white;
    width: ${props => props.width ? props.width : "100%"};
    max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
`;

const StyledTextInput = styled.input`
    /* border: none; */
    /* margin: auto; */
    border: none;
    width: 100%;
    background-color: inherit;

    &:focus {
        outline: none;
    }
`;