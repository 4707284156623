import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useState } from 'react';


const CopyButton = (props) => {
    /*
    * A button that copies a value to the clipboard.
    *
    * @param {str} value - The value to copy.
    * @returns {Button} - The copy button.
    */

    const [text, setText] = useState("Copy");

    const handleCopy = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(props.value);
        setText("Copied!");
        setTimeout(() => {
            setText("Copy");
        }, 2000);
    }

    return (
        <Button variant="info" size="sm" onClick={handleCopy}>{text}</Button>
    );
}

CopyButton.propTypes = {
    value: PropTypes.string.isRequired,
}

export default CopyButton;
