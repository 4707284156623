import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

//This page contains an FAQ section and a contact form
const About = (props) => {
    return ( 
        <Container>
            <h1>FAQ</h1>
            <p>
                Orchid is a platform for storing, reviewing, and sharing surgical videos. 
                It is designed to be easy to use and to protect patient privacy.
            </p>
            <FAQContainer>
            <h3>About My Orchid S/SL Camera</h3>
            <p>
                <b>How do I turn on my camera?</b><br/>
                Press and hold the power button until the LED begins blinking green. 
                When the LED turns solid green, the camera is ready to record.
            </p>
            <p>
                <b>How do I start a recording?</b><br/>
                Press the record button on the top of the camera. The LED will blink green
                while the camera is recording. Press the record button again to stop recording.
            </p>
            <p>
                <b>How do I align the camera with my point of view?</b><br/>
                The Orchid camera can stream video to your phone for a live preview.
                To use this feature, download the QS Camera app from the App Store or Google 
                Play. To connect your phone to the camera, press the WiFi button on the top 
                of the camera. The LED will blink blue while the camera is in WiFi mode.
                Connect to the camera's WiFi network from your phone. Open the QS Camera app
                and select your camera from the list. You should now see a live preview from
                the camera. You can use the app to adjust the camera's alignment.
            </p>
            <p>
                <b>Where does my camera store video?</b><br/>
                Your Orchid camera saves video data to a microSD card, which is located
                at the rear of the camera. Press on the card to eject it. You can read the
                microSD card with your computer (e.g. using the USB-C adapter that shipped
                with your camera). Navigate to the 'videos' folder to see your videos. The
                Orchid camera separates recordings into 4GB video chunks, so you may see multiple files
                per recording.
            </p>
            <p>
                <b>How do I keep my camera from running out of storage?</b><br/>
                The microSD card that shipped with your Orchid camera has enough memory to accomodate 8 hours
                of continuous filming. After you capture hours of video, upload the files to the Orchid cloud (following the
                instructions below). You may then delete the video from your microSD card to make room for more recordings.
            </p>
            </FAQContainer>
            <FAQContainer>
            <h3>About the Orchid Cloud Platform</h3>
            <p>
                <b>How can I upload video to the Orchid cloud?</b><br/>
                Navigate to the <u><Link to={'/upload'}>Upload</Link></u> page. Open the 'videos' folder on your microSD 
                card (refer to the instructions above), select all the files you wish to
                upload, and drag them into the upload area on the <u><Link to={'/upload'}>Upload</Link></u> page.
                The Orchid cloud platform will automatically combine video files that come from one 
                recording session into a single upload. Orchid currently supports video files in the mp4 format that are
                individually less than 5GB in size. You can upload multiple recordings at 
                once. After dragging your files into the upload area, you will be able to fill out
                case details and begin the upload. For the best upload experience, we recommend
                uploading over a wired internet connection, while your computer is plugged in.
            </p>
            <p>
                <b>What can I do with my video after I upload it?</b><br/>
                Your video is stored on the Orchid cloud platform. You can access your videos
                from the <u><Link to={'/library'}>Library</Link></u> page. Once you select a video, you can 
                edit the case details and annotate your video from the viewer page. You can also share your video 
                with other users on the platform, within groups that you have mutually joined.
            </p>
            <p>
                <b>How does the Orchid cloud protect patient privacy?</b><br/>
                The Orchid cloud platform is designed to be fully HIPAA-compliant. All video
                uploaded to the platform is encrypted at rest and in transit. Video is only
                accessible to users who have been granted access by the video owner. It is the video owner's
                responsibility to ensure that either (1) videos are only shared with a patient's care team, or (2) the 
                patient has given written consent to share the video outside of their care team, or (3) the video and its
                accompanying case details do not contain any identifying information. <br/><br/>
                Please see our <u><a href='\docs\Orchid-Surgical-Privacy-Policy.pdf' download>Platform Privacy Policies</a></u>, 
                our <u><a href='\docs\Orchid-Surgical-HIPAA-Privacy-Policies-and-Tags.pdf' download>HIPAA Privacy Policies</a></u>, 
                and our <u><a href='\docs\Orchid-Surgical-HIPAA-Security-Policies-and-Tags.pdf' download>HIPAA Security Policies</a></u> for more information.
            </p>
            </FAQContainer>
            <FAQContainer>
            <h3>Got Feedback?</h3>
            <p>
                <b>Found a bug? Got a feature request? We'd love to hear from you!</b><br/>
                Please send us an email at <u><a href="mailto:contact@orchidsurgical.com">contact@orchidsurgical.com</a></u>.
            </p>
            </FAQContainer>
        </Container>
     );
}
 
export default About;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 80px;
    padding: 20px;
    overflow: visible;
    max-width: 1080px;
`;

const FAQContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin: auto; */
    margin-top: 20px;
    padding: 20px;
    overflow: visible;
    text-align: left;
    background: #f5f5f5;
    border-radius: 40px;
`;