import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { dismissNotification } from '../services/redux/notificationsSlice';
import Alert from 'react-bootstrap/Alert';

const NotificationsWidget = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications.notifications);

    //state for the notifications
    const [notificationList, setNotificationList] = useState([]);

    //useEffect to update the notifications
    useEffect(() => {
        setNotificationList(notifications);
    }, [notifications]);

    return (
        <StyledNotificationsWidget>
            <StyledNotification>
            {notificationList.map(notification => (
                <Alert key={notification.id} variant={notification.type} onClose={() => dispatch(dismissNotification(notification.id))} dismissible>
                    <Alert.Heading>{notification.title}</Alert.Heading>
                    <p>{notification.message}</p>
                </Alert>
            ))}
            </StyledNotification>
        </StyledNotificationsWidget>
    );
}

export default NotificationsWidget;

const StyledNotificationsWidget = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    width: 400px;
    pointer-events: none;
`;

const StyledNotification = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: auto;
`;