import { createSlice } from '@reduxjs/toolkit';

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
    },
    reducers: {
        addNotification(state, action){
            //if the state exists, update it. otherwise, add it
            if(state.notifications.some(notification => notification.id === action.payload.id)){
                state.notifications = state.notifications.map(notification => {
                    if(notification.id === action.payload.id){
                        return action.payload;
                    }
                    return notification;
                });
                return;
            } else {
                state.notifications.push(action.payload);
            }
        },
        dismissNotification(state, action){
            state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
        },
        clearNotifications(state){
            state.notifications = [];
        }
    },
});

export const { 
    addNotification, 
    dismissNotification, 
    clearNotifications
 } = notificationsSlice.actions;

export default notificationsSlice.reducer;