import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import storage from 'redux-persist/lib/storage'
import userReducer from './userSlice'
import fileUploadReducer from './fileUploadSlice'
import uiReducer from './uiSlice'
import notificationsReducer from './notificationsSlice'
import timelineReducer from './timelineSlice'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedUserReducer = persistReducer(persistConfig, userReducer)
const persistedUploadReducer = persistReducer(persistConfig, fileUploadReducer)
const persistedNotificationsReducer = persistReducer(persistConfig, notificationsReducer)

const syncConfig = {
  // whitelist: ['user', 'fileUpload'],
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE', 'ui/setUploadIsOpen'],
  // predicate: (type, state) => {
  //   return true;
  // }
}

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    fileUpload: persistedUploadReducer,
    notifications: persistedNotificationsReducer,
    ui: uiReducer,
    timeline: timelineReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }
    ).concat(createStateSyncMiddleware(syncConfig)),
})

initMessageListener(store);

export const persistor = persistStore(store)