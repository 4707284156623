import { codecs, mp2t } from 'mux.js';

export function getVideoFrame(file, time) {
    return new Promise((resolve, reject) => {
        const src = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.setAttribute('crossOrigin', 'anonymous');
        video.src = src;
        video.muted = true;

        video.onloadedmetadata = () => {
            video.currentTime = time;
        }

        video.onseeked = () => {
            // video.oncanplay = () => {
            setTimeout(() => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const dataURL = canvas.toDataURL();
                video.remove();
                URL.revokeObjectURL(src);
                resolve(dataURL);
            }
            , 500);
        }
    });
}

export function getCurrentVideoFrame(playerRef, callback) {
    const video = playerRef.current.el().querySelector('video');
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 450;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL();
    callback(dataURL);
}

export async function getVideoMetadata(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
            resolve(video);
        }
    })
}

//a function to check if a file has the YYYYMMDD_HHMMSS.mp4 format
export function checkFilenameFormat (filename) {
    if (filename.length !== 19) {
        return false;
    }
    if (filename.slice(4,5) !== '0' && filename.slice(4,5) !== '1') {
        return false;
    }
    return true;
}

export async function combineMultipartVideoData (files) {
    //this function 
    //(1) determines if any files in the file list were created contiuously and 
    //(2) groups them by date

    //if filenames follow the YYYYMMDD_HHMMSS.mp4 format
    //then the files can be sorted by date
    //and the files that were created continuously can be grouped together
    if (files.length === 0) {
        return [];
    }
    if (files.some(file => !checkFilenameFormat(file.name))) {
        console.log('Filenames are not in the correct format for multipart uploads.')
        return files.map(file => [file]);
    }

    //get creation timestamps from filenames
    //the filename format is YYYYMMDD_HHMMSS.mp4
    //and the timestamps should be Date objects
    let timestamps = [];
    for (let idx = 0; idx < files.length; idx++) {
        let filename = files[idx].name;
        let timestamp = new Date(filename.slice(0,4), filename.slice(4,6), filename.slice(6,8), filename.slice(9,11), filename.slice(11,13), filename.slice(13,15));
        //go back one month, because months are zero-index in javascript (d'oh!)
        timestamp.setMonth(timestamp.getMonth() - 1);
        timestamps.push(timestamp);
    }

    //sort the files by date
    files.sort((a, b) => {
        return timestamps[files.indexOf(a)] - timestamps[files.indexOf(b)];
    });
    timestamps.sort((a, b) => { 
        return a - b;
    });

    //load the metadata from each mp4 file and store the length of the video
    let videoLengths = [];
    for (let idx = 0; idx < files.length; idx++) {
        let videoLength = 0;
        let metadata = await getVideoMetadata(files[idx]);
        videoLength = metadata.duration;
        videoLengths.push(videoLength);
        metadata.remove();
    };

    //find the files that were created continuously
    let continuousFiles = [];
    let fileCounter = 0;
    for (let idx = 0; idx < files.length; idx++) {
        //if this is the first file, add it to the list
        if (idx === 0) {
            continuousFiles.push([files[idx]]);
        } else {
            //if the time between this file and the previous file is less than the length of the previous file,
            // add it to the list
            if (timestamps[idx] - timestamps[idx - 1] < (videoLengths[idx - 1] * 1000 + 10*60*1000)) {
                continuousFiles[fileCounter].push(files[idx]);
            } else {
                //otherwise, add the current list to the list of continuous files and start a new list
                fileCounter++;
                continuousFiles.push([files[idx]]);
            }
        }
    }
    return continuousFiles;
}

export function analyzeTsFile(arrayBuffer) {
    console.log('Analyzing TS file...', arrayBuffer.byteLength);


    const tsStream = new mp2t.TransportPacketStream();
    const tpStream = new mp2t.TransportParseStream();
    const elementaryStream = new mp2t.ElementaryStream();
    const timestampRolloverStream = new mp2t.TimestampRolloverStream();
    const h264Stream = new codecs.h264.H264Stream();
    const nalByteStream = new codecs.h264.NalByteStream();

    // Pipe the TS packets to the elementary stream parser
    // tsStream.pipe(tpStream).pipe(elementaryStream);
    // Pipe the elementary stream to the H.264 stream parser
    tsStream.pipe(tpStream).pipe(elementaryStream).pipe(timestampRolloverStream)
    .pipe(nalByteStream)
    // Container for frame positions
    let frames = [];
  
    // h264Stream.on('data', (data) => {
    //     console.log('H264 data:', data)
    // });
    nalByteStream.on('data', (data) => {
        console.log('NAL data:', data)
    });
    // Listen for data events from the elementary stream
    // elementaryStream.on('data', (data) => {
    //   console.log('Elementary stream data:', data)
    //   if (data.type === 'video') {
    //     let offset = 0;
    //     while (offset < data.data.byteLength) {
    //       // Check for NAL unit start codes (0x000001 or 0x00000001)
    //       let nalUnitType;
    //       if (data.data[offset] === 0x00 &&
    //           data.data[offset + 1] === 0x00 &&
    //           data.data[offset + 2] === 0x01) {
    //         nalUnitType = data.data[offset + 3] & 0x1F;
    //         frames.push({ type: nalUnitType, dts: data.dts, bytePosition: offset });
    //         offset += 4;
    //       } else if (data.data[offset] === 0x00 &&
    //                  data.data[offset + 1] === 0x00 &&
    //                  data.data[offset + 2] === 0x00 &&
    //                  data.data[offset + 3] === 0x01) {
    //         nalUnitType = data.data[offset + 4] & 0x1F;
    //         frames.push({ type: nalUnitType, dts: data.dts, bytePosition: offset });
    //         offset += 5;
    //       } else {
    //         offset++;
    //       }
    //     }
    //   }
    // });
  
    // Feed the ArrayBuffer data into the transport stream parser
    tsStream.push(new Uint8Array(arrayBuffer));
  
    // Output the frame positions
    console.log('Frames found:', frames);
  }

export function sortVideoFilesByName (files) {

    //determine if the filenames are in orchid camera format
    //if they are, sort them by date

    if (!files.some(file => !checkFilenameFormat(file.name))) {
        console.log('sorting files')
        //get creation timestamps from filenames
        //the filename format is YYYYMMDD_HHMMSS.mp4
        //and the timestamps should be Date objects
        let timestamps = [];
        for (let idx = 0; idx < files.length; idx++) {
            let filename = files[idx].name;
            let timestamp = new Date(filename.slice(0,4), filename.slice(4,6), filename.slice(6,8), filename.slice(9,11), filename.slice(11,13), filename.slice(13,15));
            //go back one month, because months are zero-index in javascript (d'oh!)
            timestamp.setMonth(timestamp.getMonth() - 1);
            timestamps.push(timestamp);
        }

        //sort the files by date
        files.sort((a, b) => {
            return timestamps[files.indexOf(a)] - timestamps[files.indexOf(b)];
        });

    }
    return files;
}