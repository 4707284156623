const dbName = 'uploads'
const storeName = 'files'
let db

export const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1)
    
        request.onerror = (event) => {
        reject(event)
        }
    
        request.onsuccess = (event) => {
        db = event.target.result
        resolve(event)
        }
    
        request.onupgradeneeded = (event) => {
        db = event.target.result
        db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true })
        }
    })
    }

export const addFile = (file) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readwrite')
        const store = transaction.objectStore(storeName)
        const request = store.add(file)
    
        request.onerror = (event) => {
            reject(event.target.errorCode)
        }
    
        request.onsuccess = (event) => {
            resolve(event.target.result)
        }
    })
}

export const getFile = (id) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readonly')
        const store = transaction.objectStore(storeName)
        const request = store.get(id)
    
        request.onerror = (event) => {
            reject(event.target.errorCode)
        }
    
        request.onsuccess = (event) => {
            if (event.target.result) {
                resolve(event.target.result);
            } else {
                console.log("File with id", id, " not found in indexedDB");
                reject("indexedDB error: entry not found");
            }
        }
    })
}

export const deleteFile = (id) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readwrite')
        const store = transaction.objectStore(storeName)
        const request = store.delete(id)
    
        request.onerror = (event) => {
        reject(event)
        }
    
        request.onsuccess = (event) => {
        resolve(event)
        }
    })
}

export const clearFiles = () => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readwrite')
        const store = transaction.objectStore(storeName)
        const request = store.clear()
    
        request.onerror = (event) => {
        reject(event)
        }
    
        request.onsuccess = (event) => {
        resolve(event)
        }
    })
}

export const closeDB = () => {
    console.log('Closing DB')
    db.close()
}