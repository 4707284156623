import { useState, React } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Button from 'react-bootstrap/Button';
import SymbolBubble from './SymbolBubble';


const NewEntry = (props) => {
    /*
    * A component for creating a new entry.
    * 
    * @param {str} title - The title of the new entry.
    * @param {array} attributes - The attributes of the new entry.
    * Each object should have the
    * following keys:
    *  - key: The key of the attribute in the item dictionary.
    *  - label: The label to display for the attribute.
    *  - type: The type of the attribute. Can be one of the following:
    *    - string: A string value.
    *    - boolean: A boolean value.
    *    - password: A password value.
    * @param {func} create_method - The method for creating the new entry.
    * @param {object} base_data - The parameters for creating the new entry.
    * @returns {JSX} A new entry component.
    */

    const [data, setData] = useState(props.base_data);
    const [errors, setErrors] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});

    const handleDataChange = (event) => {
        if (event.target.value === "" || event.target.value === null) {
            delete data[event.target.getAttribute('attrkey')];
        } else {
            setData({
                ...data,
                [event.target.getAttribute('attrkey')]: event.target.value
            });
        }
    }

    const isValid = (attribute, value) => {
        let validator = attribute.validator ? attribute.validator : () => {return null};
        let error = validator(value);
        if (error) {
            setFieldErrors({
                ...fieldErrors,
                [attribute.key]: error
            });
            return false;
        }
        else {
            delete fieldErrors[attribute.key];
            return true;
        }
    }

    const handleCreate = () => {
        props.create_method(data).then((response) => {
            if (response) {
                window.location.reload();
            } else {
                setErrors([
                    "There was an error creating the item."
                ]);
            }
        });
    }

    return (
        <Panel>
            <h3>{props.title} (New)</h3>
            {props.attributes.map((attr) => (
                attr.settable ? (
                    <Item key={attr.key} attrCount={2}>
                        <label>
                            {attr.label}
                            {attr.required ? ("*") : null}
                            {attr.tooltip !== undefined ? (
                            <SymbolBubble
                                variant={attr.tooltip.variant ? attr.tooltip.variant : "info"}
                                text={attr.tooltip.text} />
                        ) : (null)}
                        </label>
                        {attr.type === 'boolean' ? (
                            <input type="checkbox" attrkey={attr.key} onChange={handleDataChange} />
                        ) : attr.type === 'password' ? (
                            <span>
                                <input type="password" attrkey={attr.key} onChange={handleDataChange} />
                            </span>
                        ) : attr.type === 'text' ? (
                            <span>
                                <textarea type="text" attrkey={attr.key} onChange={handleDataChange} />
                            </span>
                        ) : attr.type === 'choice' ? (
                            <span>
                                <select attrkey={attr.key} onChange={handleDataChange}>
                                    <option value="">--</option>
                                    {attr.choices.map((choice) => (
                                        <option value={choice.value}>{choice.label}</option>
                                    ))}
                                </select>
                            </span>
                        ) : (
                            <span>
                                <input type="text" attrkey={attr.key} onChange={(e) => {
                                    if (isValid(attr, e.target.value)) {
                                        handleDataChange(e);
                                    }
                                }}/>&nbsp;
                                <FieldError>{fieldErrors[attr.key]}</FieldError>
                            </span>
                        )}
                    </Item>
                ) : null
            ))}
            <Button variant="success" onClick={handleCreate}>Create</Button>
            <span>
                {errors.map((error) => (
                    <p>{error}</p>
                ))}
            </span>
        </Panel>
    )
}

NewEntry.propTypes = {
    title: PropTypes.string.isRequired,
    attributes: PropTypes.array.isRequired,
    create_method: PropTypes.func.isRequired,
    base_data: PropTypes.object,
}

NewEntry.defaultProps = {
    base_data: {},
}

export default NewEntry;


const Panel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.6);
    width: 95%;
    margin: 5px 0px 0px 0px;
`;

const Item = styled.div`
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.attrCount}, minmax(175px, 1fr))`};
    grid-gap: 0px;
    justify-items: left;
    align-items: left;
    background-color: rgba(200, 200, 200, 0.6);
    width: 100%;
    padding: 3px 20px;
    vertical-align: middle;
`;

const FieldError = styled.span`
    color: red;
    font-size: 1em;
`;
