import {get, post} from './common.js';

export function authenticate(credentials) {
    return post('/auth/login/', credentials);
}

export function renewToken() {
    return post('/auth/renew/');
}

export function logout() {
    return get('/auth/logout/'); 
}

export function requestPasswordReset(email) {
    return post('/auth/request_reset/', {'email': email});
}

export function resetPassword(email, token, newPassword, first_name, last_name) {
    return post('/auth/reset/', {'email': email, 'code': token, 'password': newPassword, 'first_name': first_name, 'last_name': last_name});
}