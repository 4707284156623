export function dateFromTimestamp(timestamp) {
    // timestamp = timestamp.split('+')[0] + 'Z';
    const date = new Date(timestamp.split('+')[0] + 'Z');
    return date.toLocaleDateString();
}

export function ageFromTimestamp(timestamp) {
    // timestamp = timestamp.split('+')[0] + 'Z';
    const timeCreated = new Date(timestamp.split('+')[0] + 'Z');
    const timeNow = new Date();
    const timeDiff = timeNow - timeCreated;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const seconds = Math.floor(timeDiff / 1000);
    if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds} seconds ago`;
    }
}

export function messageRecencyFromTimestamp(timestamp) {
    // timestamp = timestamp.split('+')[0] + 'Z';
    const timeCreated = new Date(timestamp.split('+')[0] + 'Z');
    const timeNow = new Date();
    const timeDiff = timeNow - timeCreated;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const seconds = Math.floor(timeDiff / 1000);
    if (days > 1) {
        return dateFromTimestamp(timestamp);
    } else if (days === 1) {
        return `Yesterday`;
    } else if (hours > 1) {
        return `Today`;
    } else if (hours === 1) {
        return `1 hour ago`;
    } else if (minutes > 1) {
        return `${minutes} minutes ago`;
    } else {
        return `Just Now`;
    }
}

export function timeFromTimestamp(timestamp) {
    // timestamp = timestamp.split('+')[0] + 'Z';
    const date = new Date(timestamp.split('+')[0] + 'Z');
    return date.toLocaleTimeString();
}

export function hmsFromSeconds(seconds) {
    if (seconds === null) {
        return '';
    }

    const hours = Math.floor(seconds / (60 * 60));
    const minutes = Math.floor(seconds / 60 - hours * 60);
    const secondsLeft = String(Math.round(seconds % 60)).padStart(2, '0');

    if (hours === 0) {
        return `${minutes}:${secondsLeft}`;
    } 
    return `${hours}:${minutes}:${secondsLeft}`;
}

export function hmsTextFromSeconds(seconds, format='hms') {
    if (seconds === null) {
        return '';
    }

    let hours = Math.floor(seconds / (60 * 60));
    let minutes = Math.floor(seconds / 60 - hours * 60);
    let secondsLeft = Math.round(seconds % 60);

    if (secondsLeft === 60) {
        minutes += 1;
        secondsLeft = 0;
    }
    if (minutes === 60) {
        hours += 1;
        minutes = 0;
    }

    //if format is hms, return hh h, mm m, ss s
    //if format is hm, return hh h, mm m
    //only return nonzero values
    let text = '';
    if (format === 'hms') {
        if (hours > 0) {
            text += `${hours}h `;
        }
        if (minutes > 0) {
            text += `${minutes}m `;
        }
        if (secondsLeft > 0) {
            text += `${secondsLeft}s`;
        }
    } else if (format === 'hm') {
        if (hours > 0) {
            text += `${hours}h `;
        }
        if (minutes > 0) {
            text += `${minutes + Math.ceil(secondsLeft/60)}m`;
        }
    } 
    return text;
} 

//function to cut a string to a maximum character count
export function reduceString(string, maxLength) {
    if (string === null) {
        return '';
    }
    else if (string.length <= maxLength) {
        return string;
    } else {
        return string.substring(0, maxLength) + '...';
    }
}

//function to cut a string to a maximum word count
export function reduceStringWords(string, maxWords) {
    if (string === null) {
        return '';
    }
    else if (string.split(' ').length <= maxWords) {
        return string;
    } else {
        return string.split(' ').slice(0, maxWords).join(' ') + '...';
    }
}