import styled from 'styled-components';
import { useState, useEffect } from 'react';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import { getAgreementAdmin, updateAgreementAdmin } from '../services/api/admin_agreements.js';


const AdminAgreement = (props) => {

    const { agreement_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "name",
            "label": "Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "description",
            "label": "Description",
            "type": "text",
            "settable": true,
        },
        {
            "key": "required",
            "label": "Required?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "group_id",
            "label": "Group ID",
            "type": "string",
            "settable": true,
        },
        {
            "key": "organization_id",
            "label": "Organization ID",
            "type": "string",
            "settable": true,
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "settable": false,
        },
        {
            "key": "updated_on",
            "label": "Updated At",
            "type": "string",
            "settable": false,
        },
        {
            "label": "Versions",
            "type": "link",
            "detailLink": "/admin/agreements/<id>/versions",
            "settable": false
        }
    ];

    return (
        <Page>
            <DetailView
                title="Admin: Agreement"
                get_method={getAgreementAdmin}
                update_method={updateAgreementAdmin}
                attributes={attributes}
                primary_key={agreement_id} />
        </Page>
    );

};

export default AdminAgreement;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
