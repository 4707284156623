import styled from 'styled-components';
import { useState, useEffect } from 'react';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import { getAgreementVersionAdmin, updateAgreementVersionAdmin } from '../services/api/admin_agreements.js';


const AdminAgreementVersion = (props) => {

    const { agreement_id, version_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "agreement_id",
            "label": "Agreement ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "version",
            "label": "Version",
            "type": "string",
            "settable": false
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "settable": false,
        },
        {
            "key": "download_link",
            "label": "Download",
            "type": "external_link",
            "settable": false,
        },
        {
            "key": "upload_link",
            "label": "Upload",
            "type": "upload",
            "settable": false,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": true,
        }
    ];

    return (
        <Page>
            <DetailView
                title="Admin: Agreement Version"
                get_method={getAgreementVersionAdmin}
                update_method={updateAgreementVersionAdmin}
                attributes={attributes}
                primary_key={version_id} />
        </Page>
    );

}

export default AdminAgreementVersion;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
