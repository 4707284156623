import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getMetricsAdmin } from '../services/api/admin_metrics.js';
import Accordion from 'react-bootstrap/Accordion';


const AdminMetrics = (props) => {

    const [data, setData] = useState({});

    const loadData = () => {
        getMetricsAdmin().then((response) => {
            setData(response);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    const makeTitle = (key) => {
        return key.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
    }

    return (
        <Page>
            <h1>Metrics</h1>
            <Accordion style={{width: "98vw"}} defaultActiveKey="totals">
            {data && Object.keys(data).map((key, index) => {
                return (
                    <Accordion.Item eventKey={key} key={key}>
                        <Accordion.Header>{makeTitle(key)}</Accordion.Header>
                        <Accordion.Body>
                            {Object.keys(data[key]).map((subkey) => {
                                return (
                                    <div key={subkey}>
                                        <b>{makeTitle(subkey)}</b>
                                        <p>{data[key][subkey]}</p>
                                    </div>
                                );
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                );
            })}
            </Accordion>
        </Page>
    );
};

export default AdminMetrics;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
