import styled from 'styled-components';
import { useState, useEffect } from 'react';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import { getOrganizationAdmin, updateOrganizationAdmin, deleteOrganizationAdmin } from '../services/api/admin_organizations.js';


const AdminOrganization = (props) => {

    const { organization_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "name",
            "label": "Name",
            "type": "string",
            "settable": true,
        },
        {
            "key": "owner_id",
            "label": "Owner ID",
            "type": "string",
            "settable": true,
        },
        {
            "key": "is_private",
            "label": "Private?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "settable": false,
        },
        {
            "key": "updated_on",
            "label": "Updated At",
            "type": "string",
            "settable": false,
        },
        {
            "key": "avatar_url",
            "label": "Download Avatar",
            "type": "external_link",
            "settable": false,
        },
        {
            "key": "avatar_upload_url",
            "label": "Upload Avatar",
            "type": "upload",
            "settable": false,
        },
        {
            "label": "Members",
            "type": "link",
            "detailLink": "/admin/organizations/<id>/members",
            "settable": false,
        }
    ];

    return (
        <Page>
            <DetailView
                title="Admin: Organization"
                get_method={getOrganizationAdmin}
                update_method={updateOrganizationAdmin}
                delete_method={deleteOrganizationAdmin}
                attributes={attributes}
                primary_key={organization_id} />
        </Page>
    );

};

export default AdminOrganization;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
