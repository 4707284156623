import React from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { getGroup, getGroupMembers, getSharedAttachments, sendMessage, getGroupMessages } from "../services/api/groups.js";
import Spinner from 'react-bootstrap/Spinner';
import VideoList from "./VideoList";
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import ChatArea from "./ChatArea.js";
import useWindowSize from "../hooks/useWindowSize.js";
import MobileHeader from "./MobileHeader.js";
import MobileVideoList from "./MobileVideoList.js";


const GroupHome = (props) => {

    const { group_id } = useParams();
    const [group, setGroup] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const user = useSelector(state => state.user.value);
    const windowSize = useWindowSize();
    
    useEffect(() => {


        getGroup(group_id).then(items => {
            setGroup(items);
        })

        getSharedAttachments(group_id).then(items => {
            setAttachments(items.reverse().map((item)=>item.attachment));
        })

    }, [group_id])

    return ( 
        <>
        {group &&
        <Container>
        {(windowSize.width > 899) ? (
            <>
            <TitleLayout>
            <img 
                src={group.avatar_url} 
                height='75'
                onError={(e) => e.target.style.display='none'}
            />
                <TitleText>
                    <h1>{group.name}</h1>
                    <span>{group.description}</span>
                </TitleText>
            </TitleLayout>
            <PageLayout>
                <ChatArea group_id={group_id} no_logo={true}/>
                <VideoArea>
                    <VideoList 
                        attachments={attachments} 
                        title="Group Library"
                        emptyMessage="Be the first one to share a video!"
                        />
                </VideoArea>
            </PageLayout>
            </>
        ) : (
        <>
        <MobileHeader text={group.name}/>
        <MobileVideoList 
            attachments={attachments} 
            // setRequestNextPage={setRequestNextPage} 
            searchable={true}/>
        </>
        )}
        </Container>
        }
    </>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 1920px;

    @media (max-width: 899px) {
        padding: 0;
        /* height: calc(100vh - 60px); */
        overflow-y: auto;
    }
`;

const PageLayout = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    flex: 1 1 0;
    overflow: hidden;
    
`;

const TitleLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex: 0 0 120px;
    `;

const TitleText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    justify-content: center;
    height: 100%;
`;

const VideoArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

export default GroupHome;