import React from "react";
import styled from "styled-components";

const Button = ({text, color, textColor, onClick, width, disabled}) => {
    return (
        <StyledButton 
            color={color} 
            textColor={textColor} 
            onClick={disabled ? null : onClick}
            width={width}
            disabled={disabled}
            >
            {text}
        </StyledButton>
    );
}

export default Button;

const StyledButton = styled.button`
    position: relative;
    background-color: ${props => props.color};
    border: none;
    border-radius: 50px;
    color: ${props => props.textColor};
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 20px;
    width: ${props => props.width ? props.width : 'fit-content'};
    height: fit-content;
    white-space: nowrap;
    transition: all .2s ease;
    opacity: ${props => props.disabled ? .5 : 1};

    &:hover {
        filter: brightness(.9);
    }       
`;