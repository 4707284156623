import Modal from 'react-bootstrap/Modal';
import { useState, useRef, useEffect} from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { getGroupMembers, getGroups } from "../services/api/groups.js"
import { getShares, createShare, deleteShare, updateAttachment, getAttachment } from '../services/api/attachments.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SymbolBubble from './SymbolBubble.js';
import mixpanel from 'mixpanel-browser';

function CasePHIModal(props) {
    const [isSharedWithGroup, setIsSharedWithGroup] = useState([]);
    const [shares, setShares] = useState([]);
    const [videoContainsPHI, setVideoContainsPHI] = useState(null);
    const [videoHasConsent, setVideoHasConsent] = useState(null);
    const [videoCanBeShared, setVideoCanBeShared] = useState(null);
    const [activeTab, setActiveTab] = useState('groups')
    const mounted = useRef(true);

    useEffect(() => {
        if (props.attachment) {
            getAttachment(props.attachment.id)
            .then(attachment => {
                console.log('got attachment', attachment)
                setVideoContainsPHI(attachment.contains_phi);
                setVideoHasConsent(attachment.with_patient_consent);
                if (attachment.contains_phi === null || attachment.with_patient_consent === null)
                    setActiveTab('phi-info')
            })
        }
    }, [props.show])

    //useEffect to monitor sharing permissions
    useEffect(() => {
        if (props.attachment) {
            setSharingEligibility();
            setSharedStatus(props.attachment.id);
        }
    }, [videoContainsPHI, videoHasConsent])

    function setSharingEligibility() {
    }

    function setSharedStatus (attachment_id) {
    }

    function checkSharingEligibility (group) {
        if (group.allow_phi === false) {
            //no phi is allowed, ever
            if (videoContainsPHI === false && videoHasConsent != null )
                return true;
            else
                return false;
        }
        else if (group.allow_phi === true && group.require_patient_consent === true) {
            //phi is allowed, but only with consent
            if (videoContainsPHI === false && videoHasConsent != null)
                //no phi, no problem
                return true;
            else if (videoContainsPHI === true && videoHasConsent === true)
                //phi, but with consent
                return true;
            else
                //phi, but no consent
                return false;
        }
        else if (group.allow_phi === true && group.require_patient_consent === false) {
            //this is a care team group, so phi is allowed without consent
            if (videoContainsPHI != null && videoHasConsent != null)
                return true;
            else
                return false;
        }
        else
            return false;
    }

    const onPHIChange = (value) => {
        console.log("Contains PHI: ", value)
        updateAttachment(props.attachment.id, { "contains_phi" : value }).then((response) => {
            if (response)
                setVideoContainsPHI(response.contains_phi)
            mixpanel.track('Updated Video PHI', 
                {
                    'Source': 'Messages Page', 
                    'Attachment ID': props.attachment.id,
                    'Value': value
                }
            )
        })
    }

    const onConsentChange = (value) => {
        console.log("Has consent: ", value)
        updateAttachment(props.attachment.id, { "with_patient_consent" : value }).then((response) => {
            if (response)
                setVideoHasConsent(response.with_patient_consent)
            mixpanel.track('Updated Video Consent', 
                {
                    'Source': 'Messages Page', 
                    'Attachment ID': props.attachment.id,
                    'Value': value
                }
            )
        })
    }
    
    return (
    <Modal
        {...props}
        size="lg"
        centered
    >
        {props.attachment &&
        <>
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Share "{props.attachment.case.case_name}"
        </Modal.Title>
        </Modal.Header>
            <Modal.Body>
                To enable sharing features for this video, you must complete the 
                following attestations. 
                <SymbolBubble text="If you update these attestations after sharing, your video will be 
                removed from any groups or messages whose PHI policies are not compatible with the new settings." variant='warning'></SymbolBubble>
                    <br/><br/>
            <Form>
            <Container>
                <Row>
                <Col xs={8}>
                <span>Does this video or its title/description contain PHI?</span>
                </Col>
                <Col xs={2}>
                <Form.Check
                    inline
                    checked={videoContainsPHI === true}
                    onChange={() => {onPHIChange(true)}}
                    type='radio'
                    name='phi-radio'
                    label={`Yes`}
                    id={`phi-radio-yes`}
                />
                </Col>
                <Col xs={2}>
                <Form.Check
                    inline
                    checked={videoContainsPHI === false}
                    onChange={(event) => {onPHIChange(false)}}
                    type='radio'
                    name='phi-radio'
                    label={`No`}
                    id={`phi-radio-no`}
                />
                </Col>
                </Row>
                <Row>
                <Col xs={8}>
                <span>Have you obtained the patient's written consent to disclose PHI?</span>
                </Col>
                <Col xs={2}>
                <Form.Check
                    inline
                    checked={videoHasConsent === true}
                    onChange={() => onConsentChange(true)}
                    type='radio'
                    name='consent-radio'
                    label={`Yes`}
                    id={`consent-radio-yes`}
                />
                </Col>
                <Col xs={2}>
                <Form.Check
                    inline
                    checked={videoHasConsent === false}
                    onChange={() => onConsentChange(false)}
                    type='radio'
                    name='consent-radio'
                    label={`No`}
                    id={`consent-radio-no`}
                />
                </Col>
            </Row>
            </Container>
        </Form>
        <br/>
        
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Done</Button>
        </Modal.Footer>
        </>
        }
    </Modal>
    );
}

    export default CasePHIModal