import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import { getMe } from "../services/api/me";

const ProtectedAdminRoute = ({
    user,
    setUser,
    redirectPath = '/login',
    children,
  }) => {

    const location = useLocation();
    const navigate = useNavigate();
    // const { user, setUser } = useUser();
    // useEffect hook to run auth check
    useLayoutEffect(() => {
      // console.log('ProtectedRoute: useLayoutEffect');
      //check if the user is already logged in
      getMe().then(data => {
          if (data) {
            setUser(data);
          } else {
            setUser('');
            navigate(redirectPath);
          }
      })
    }, [location.pathname])
    if (!user.is_admin) {
      return <p><br/><br/><br/>There's nothing here: 404!</p>;
    }
    return children ? children : <Outlet />;
  };

export default ProtectedAdminRoute;