import {get, post} from './common.js';

//get all organizations
export function getOrganizations() {
    return get('/organizations/');
}

//get organization by id
export function getOrganization(id) {
    return get(`/organizations/${id}/`);
}