import React from 'react';
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';


const SymbolBubble = ({text, variant, symbol="i", backgroundColor="#007bff"}) => {
    /* This component displays a small bubble with a symbol in it.
    *
    *  Props:
    *    text: string, the text to display in the tooltip
    *    variant: string, the variant of the bubble (info, warning, danger, success, public, null)
    *    symbol: string, the symbol to display in the bubble
    *    backgroundColor: string, the background color of the bubble
    */
    switch (variant) {
        case "info":
            backgroundColor = "#007bff";
            symbol = "i";
            break;
        case "warning":
            backgroundColor = "#ffc107";
            symbol = "▲";
            break;
        case "danger":
        case "error":
            backgroundColor = "#dc3545";
            symbol = "!";
            break;
        case "success":
            backgroundColor = "#28a745";
            symbol = "✓";
            break;
        case "public":
            backgroundColor = "#28a745";
            symbol = "public";
            break;
        default:
            backgroundColor = backgroundColor;
            symbol = symbol;
            break;
    }

    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id='infoInfo'>{text}</Tooltip>}
            onClick={(e) => e.preventDefault()}
        >
            <Bubble backgroundColor={backgroundColor}>{symbol}</Bubble>
        </OverlayTrigger>
    )
}

SymbolBubble.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["info", "warning", "danger", "error", "success", "public", null]),
    symbol: PropTypes.string,
    backgroundColor: PropTypes.string,
}

export default SymbolBubble;

const Bubble = styled.div`
    border: none;
    border-radius:8px;
    height: 16.5px;
    min-width: 16.5px;
    text-align: center;
    font-size: 11px;
    color: white;
    cursor: pointer;
    font-weight: bolder;
    margin-left: 5px;
    padding: 0px 5px;
    display: inline-block;
    background-color: ${props => props.backgroundColor};
`;
