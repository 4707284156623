import {get, post, patch, del} from './common.js';

//get all cases
export function getCases() {
    return get('/cases/');
}

//get case by id
export function getCase(id) {
    return get(`/cases/${id}/`);
}

//create case
export function createCase(data) {
    return post('/cases/', data);
}

//delete case by id
export function deleteCase(id) {
    return del(`/cases/${id}/`);
}

//update case by id
export function updateCase(id, data) {
    return patch(`/cases/${id}/`, data);
}