import React from "react";
import styled from "styled-components";
import TextInput from "./TextInput";
import mixpanel from "mixpanel-browser";

const Form = ({placeholder, text, buttonColor, buttonTextColor, width, maxWidth, formName, formValue, formType}) => {

    const [submitted, setSubmitted] = React.useState(0);

    function handleSubmitForm(e) {
        e.preventDefault();
        const form = e.target;
        setSubmitted(1)
    
        // Post data using the Fetch API
        fetch(form.action, {
            method: form.method,
            body: new FormData(form),
        })
        .then(response => {
            if (response.status === 200) {
                setSubmitted(2)
                if (formValue === 'WaitList')
                  mixpanel.track('Joined Waitlist', {'Source': 'Landing Page', 'Email': form.EMAIL.value})
                else if (formValue === 'SalesRequest')
                  mixpanel.track('Sales Request', {'Source': 'Landing Page', 'Email': form.EMAIL.value, 'Type': formType})
                else if (formValue === 'Newsletter')
                  mixpanel.track('Joined Newsletter', {'Source': 'Landing Page', 'Email': form.EMAIL.value})
                return true;
            }
            else {
                setSubmitted(3)
                return false;
            }
        })
      };

  return (
    <>
    { (submitted !=2) ?
    <form 
    id='waitlist-form'
    action="https://script.google.com/macros/s/AKfycbxMqWXWiHIa9zSy4LJ838bAsO4_toAWu6qtoMeLF9F57TzARoJP2_tHnh-dnYQZm58/exec" 
    method="post"
    onSubmit={(e) => {handleSubmitForm(e)}}
    style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
    >
      <input type="hidden" name={formName} value={formValue} />
      <input type="hidden" name="TYPE" value={formType} />
      <TextInput
        placeholder={placeholder}
        text={text}
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
        name='EMAIL' 
        width={width}
        maxWidth={maxWidth}
        waiting={submitted === 1}
      />
      { submitted === 3 ? <span>There was an error. Please try again.</span> : null}
  </form> :
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'gray', whiteSpace: 'pre-wrap'}}>
        <b>Thank you for your interest! <wbr/>We will be in touch soon.</b>
    </div>
    }
  </>
  );
}

export default Form;