import React from "react";
import mixpanel from "mixpanel-browser";

function useScrollReporter(sourcePage) {

    React.useEffect(() => {
        let scrollControl = new AbortController();
        let scrollSubmitted = false;
        //detect if the user scrolls to the bottom
        window.addEventListener("scroll", () => {
          if (((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) && !scrollSubmitted) {
            scrollSubmitted=true;
            mixpanel.track("Scrolled Down", {'Source': sourcePage});
          }
        }, {passive: true, signal: scrollControl.signal});
        return () => {
          scrollControl.abort();
        }
      }, [])
};

export default useScrollReporter;
