import { get, paramsToString } from "./common.js";

export function searchUsers(query) {
    if (typeof query === 'string') query = {'term': query}
    let paramString = paramsToString(query);
    return get(`/search/users/${paramString}`);
}

export function searchTags(query) {
    if (typeof query === 'string') query = {'term': query}
    let paramString = paramsToString(query);
    return get(`/search/tags/${paramString}`);
}
