import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BackgroundShapes, Panel, HeaderBackground, StyledLandingPage, MobileStyledLandingPage} from "./LandingPage";
import { GlassContainer } from "../components/GlassContainer";
import Form from '../components/Form';
import useScrollReporter from "../../hooks/useScrollReporter";

const ForTeams = () => {

    React.useEffect(() => {
        document.title = "Orchid Cloud | For Surgical Teams";
      }, [])
    useScrollReporter('For Teams')

    return (
        <>
        <MobileStyledLandingPage>
        <HeaderBackground>
            <img src="/images/store/ForTeams/background_shapes.svg" alt="Orchid Surgical" height ='100%'/>
        </HeaderBackground>
        <Header />
        <TitlePanel>
            <StyledText>
                <div style={{width: '100%', textAlign: 'left'}}>
                    <span style={{
                        color: '#303030', 
                        fontSize: 45, 
                        fontFamily: 'Lato', 
                        fontWeight: '600',
                    }}
                    >
                        One Platform For Your Team To
                    </span>
                    <span style={{
                        color: '#357657', 
                        fontSize: 45, 
                        fontFamily: 'Poppins', 
                        fontWeight: '700', 
                    }}><wbr/> Collaborate, Coach, & Build Your Library
                    </span>
                    <br/>
                </div>
                <Form
                placeholder="Your Email"
                text="Contact Sales"
                buttonColor="#47719D"
                buttonTextColor="#ffffff"
                maxWidth={"400px"}
                formName="LIST"
                formValue="SalesRequest"
                formType="Teams"
                /> 
            </StyledText>
        </TitlePanel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '45px', lineHeight: ''}}>
                    <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Collaboration
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Is Front & Center
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg1.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForTeams/messaging.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '600px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Your private Orchid group becomes a turn-key solution for sharing expertise across an institution to empower experts and support younger surgeons.
                    </span>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '45px', lineHeight: '70px'}}>
                    <span style={{color: "#27533E", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Coaching
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Made Easy
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg2.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForTeams/Scoring_Panel.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Evaluation and coaching becomes easy, with purpose-built tools integrated right into the Orchid video player. Team members can even anonymize their videos to get blinded feedback from peers and mentors.
                    </span>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '45px', lineHeight: '70px'}}>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    Build A
                    </span>
                    <span style={{color: "#431AE7", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                    <wbr/> Living Case Library
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg3.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForTeams/Library.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '550px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Clinical practice can sometimes feel cyclical, but with Orchid Groups, your team’s expertise can become institutional memory. Your library can be used internally for your trainees, or shared with the broader community to elevate the standard of care in your field.
                    </span>
                </div>
            </div>            
        </Panel>
        <Footer />
        </MobileStyledLandingPage>
        <StyledLandingPage>
        <HeaderBackground>
            <img src="/images/store/ForTeams/background_shapes.svg" alt="Orchid Surgical" height ='100%'/>
        </HeaderBackground>
        <Header />
        <TitlePanel>
        <GlassContainer>
            <StyledText>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <span style={{
                        color: '#303030', 
                        fontSize: 55, 
                        fontFamily: 'Lato', 
                        fontWeight: '600', 
                    }}
                    >
                        One Platform For Your Team To<br/>
                    </span>
                    <span style={{
                        color: '#357657', 
                        fontSize: 55, 
                        fontFamily: 'Poppins', 
                        fontWeight: '700', 
                    }}>Collaborate, Coach, <br/>& Build Your Library
                    </span>
                    <br/>
                </div>
                <Form
                placeholder="Your Email"
                text="Contact Sales"
                buttonColor="#47719D"
                buttonTextColor="#ffffff"
                width="400px"
                formName="LIST"
                formValue="SalesRequest"
                formType="Teams"
                /> 
            </StyledText>
        </GlassContainer>
        </TitlePanel>
        <Panel>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg1.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForTeams/messaging.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap",  hyphens: 'manual'}}>
                    Collab&shy;oration
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Is Front & Center
                    </span>
                </div>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Your private Orchid group becomes a turn-key solution for sharing expertise across an institution to empower experts and support younger surgeons.
                    </span>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#27533E", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Coaching
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Made Easy
                    </span>
                </div>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Evaluation and coaching becomes easy, with purpose-built tools integrated right into the Orchid video player. Team members can even anonymize their videos to get blinded feedback from peers and mentors.
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg2.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForTeams/Scoring_Panel.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>
        </Panel>
        <Panel>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg3.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForTeams/Library.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    Build A
                    </span>
                    <span style={{color: "#431AE7", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                    <wbr/> Living Case Library
                    </span>
                </div>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Clinical practice can sometimes feel cyclical, but with Orchid Groups, your team’s expertise can become institutional memory. Your library can be used internally for your trainees, or shared with the broader community to elevate the standard of care in your field.
                    </span>
                </div>
            </div>            
        </Panel>
        <Footer />
        </StyledLandingPage>
        </>
    );
    };

    export default ForTeams;

const TitlePanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    height: 100%;
    margin-bottom: 50px;
    max-width: 1600px;
`;

const StyledText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 50px;
    gap: 50px;

    @media (max-width: 900px) {
        padding: 50px 10px;
    }

`;



