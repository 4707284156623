import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CopyButton from './CopyButton';
import ConfirmationModal from './ConfirmationModal';


const ListView = (props) => {
    /*
    * A component for rendering a list of items, including support 
    * for pagination and query-string filtering.
    * 
    * @param {func} list_method - The method to call to get the list of items.
    * @param {array} attributes - An array of objects, each representing an
    * attribute of the items to be displayed. Each object should have the
    * following keys:
    *  - key: The key of the attribute in the item dictionary.
    *  - label: The label to display for the attribute.
    *  - type: The type of the attribute. Can be one of the following:
    *    - string: A string value.
    *    - boolean: A boolean value.
    *    - copy: A copy button, which copies the value to the clipboard.
    *    - link: A link to another page.
    *  - filterable: A boolean indicating whether the attribute should be
    *  filterable.
    *  - detailLink: A string representing the link to the detail page for
    *  the item. The string should contain :key placeholders for the item's
    *  keys.
    * 
    * NOTE: Composite keys are supported. For example, if the item has a
    * 'user' key, and the user has a 'name' key, the attribute key would be
    * 'user.name'.
    * 
    * @param {func} delete_method - The method to call to delete an item.
    * @param {bool} paginate - A boolean indicating whether the list should
    * be paginated. Defaults to false.
    * @param {int} max_per_page - The maximum number of items to display per
    * page. Defaults to 50.
    * @param {dict} list_query_params - A dictionary of static query parameters to
    * pass to the list_method.
    */

    let hasFilters = props.attributes.map((attr) => attr.filterable).includes(true);
    let attrCount = props.attributes.length + 1;

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(props.max_per_page);
    const [filters, setFilters] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItem, setDeleteItem] = useState({"id": null, "email": null});
    let deleteMessage = (
        `Are you sure you want to delete this item?` +
        `\n\nID: ${deleteItem.id}` +
        `\n\nNOTE: This action may not be reversible.`
    )

    function showModal (item) {
        /*
        * Sets the deleteId state and shows the delete confirmation modal.
        * 
        * @param {str} id - The id of the item to delete.
        * @returns {null}
        */
        setDeleteItem(item);
        setShowDeleteModal(true);
    }

    function hideModal () {
        /*
        * Hides the delete confirmation modal.
        * 
        * @returns {null}
        */
        setDeleteItem({"id": null, "email": null});
        setShowDeleteModal(false);
    }

    function getQueryParams () {
        /*
        * Returns a dictionary of query parameters to be passed to the
        * list_method.
        * 
        * @returns {dict} params - The query parameters.
        */
        let params = props.list_query_params;
        if (props.paginate) {
            params['page'] = page;
            params['per_page'] = perPage;
        }

        params = {
            ...params,
            ...filters,
        }

        return params;
    }

    const handleFilterChange = (event) => {
        /*
        * Updates the filters state when a filter input is changed.
        * If the input is empty, the filter is removed from the state.
        * 
        * @param {event} event - The event object.
        * @returns {null}
        */
        if (event.target.value === '' || event.target.value === null) {
            let filters_copy = filters;
            delete filters_copy[event.target.getAttribute('filterattr')];
            setFilters(filters_copy);
        } else {
            setFilters({
                ...filters,
                [event.target.getAttribute('filterattr')]: event.target.value
            })
        }
    }

    const handleDelete = (id) => {
        /*
        * Deletes an item from the list.
        * Fires a request against the delete_method prop, then reloads the list.
        * 
        * @param {str} id - The id of the item to delete.
        * @returns {null}
        */
        props.delete_method(id).then(() => {
            loadData();
        }).catch(error => {
            console.error('There was a problem with the Fetch operation:', error);
        })
    }

    const extractFromAttribute = (key, item) => {
        /*
        * Extracts a value from an attribute key.
        *
        * @param {str} key - The attribute key.
        * @returns {str} key - The extracted value.
        */
        let key_parts = key.split('.');
        let value = item;
        for (const key_part of key_parts) {
            value = value[key_part];
        }
        return value;
    }

    const loadData = () => {
        /*
        * Loads the list of items, using the list_method prop.
        *
        * @returns {null}
        */
        props.list_method(getQueryParams()).then(items => {
            setItems(items);
        }).catch(error => {
            console.error('There was a problem with the Fetch operation:', error);
        })
    }

    const makeDetailLink = (detail_link, item) => {
        /*
        * Replaces the :key placeholders in the detail_link with the
        * corresponding values from the item.
        * 
        * @param {str} detail_link - The detail link string.
        * @param {dict} item - The item to use for replacement.
        * @returns {str} detail_link - The updated detail link string.
        */
        for (const [key, value] of Object.entries(item)) {
            detail_link = detail_link.replace(`<${key}>`, value);
        }
        return detail_link;
    }

    useEffect(() => {
        loadData();
    }, [page, perPage]);

    const renderFilters = () => {
        /*
        * Renders the filter row.
        *
        * @returns {str} - The filter row HTML.
        */
        return (
            <FilterRow attrCount={attrCount}>
                {props.attributes.map((attr, index) => (
                    attr.filterable ? (
                        attr.type === 'boolean' ? (
                            <select filterattr={attr.key} onChange={handleFilterChange}>
                                <option value="">All</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        ) : attr.type == 'choice' ? (
                            <select filterattr={attr.key} onChange={handleFilterChange}>
                                <option value="">All</option>
                                {attr.choices.map((choice, index) => (
                                    <option value={choice.value}>{choice.label}</option>
                                ))}
                            </select>
                        ) : (
                            <input
                                type="text"
                                placeholder={attr.label}
                                filterattr={attr.key}
                                onChange={handleFilterChange}
                            />
                        )
                    ) : (
                        <span></span>
                    )
                ))}
                <ActionButton onClick={loadData}>Filter</ActionButton>
            </FilterRow>
        )
    };

    const renderPagination = () => {
        /*
        * Renders the pagination row.
        *
        * @returns {str} - The pagination row HTML.
        */
        return (
            <Pagination>
                <ActionButton onClick={() => setPage(page - 1)} disabled={page === 1}>Prev</ActionButton>
                <span>{page}</span>
                <ActionButton onClick={() => setPage(page + 1)} disabled={items.length < perPage}>Next</ActionButton>
            </Pagination>
        )
    };

    return (
        <List>
            <h3>{ props.title }</h3>
            <ListItem attrCount={attrCount}>
                {props.attributes.map((attr, index) => (
                    <b>{attr.label}</b>
                ))}
            </ListItem>
            {hasFilters ? renderFilters() : null}
            {items.map((item, index) => (
                <ListItem key={index} attrCount={attrCount}>
                    {props.attributes.map((attr, index) => (
                        attr.type === 'boolean' ? (
                            <span>{extractFromAttribute(attr.key, item) ? 'Yes' : 'No'}</span>
                        ) : attr.type === 'copy' ? (
                            <span>
                                <CopyButton value={extractFromAttribute(attr.key, item)} />
                            </span>
                        ) : attr.type === 'link' && attr.detailLink !== undefined ? (
                            <StyledLink onClick={() => window.location.href = makeDetailLink(attr.detailLink, item) }>{attr.label}</StyledLink>
                        ) : attr.type === 'external_link' ? (
                            <StyledLink onClick={() => window.open(extractFromAttribute(attr.key, item))}>{attr.label}</StyledLink>
                        ) : attr.detailLink !== undefined ? (
                            <StyledLink onClick={() => window.location.href = makeDetailLink(attr.detailLink, item) }>{extractFromAttribute(attr.key, item)}</StyledLink>
                        ) : (
                            <span>{extractFromAttribute(attr.key, item)}</span>
                        )
                    ))}
                    {props.delete_method ? (
                        <ActionButton variant="danger" size="sm" onClick={() => showModal(item)}>Delete</ActionButton>
                    ) : null}
                </ListItem>
            ))}
            {props.paginate ? (
                renderPagination()
            ) : null}

            <ConfirmationModal
                show={showDeleteModal}
                title="Confirm delete?"
                message={deleteMessage}
                onConfirm={() => {handleDelete(deleteItem.id)}}
                onHide={() => {hideModal()}}
                signatureRequired={true}
                signatureMessage='Please type "delete" to confirm.'
                signatureChallenge='delete' />
        </List>
    );
}

ListView.propTypes = {
    list_method: PropTypes.func.isRequired,
    list_query_params: PropTypes.object,
    attributes: PropTypes.array.isRequired,
    delete_method: PropTypes.func,
    paginate: PropTypes.bool,
    max_per_page: PropTypes.number,
};

ListView.defaultProps = {
    delete_method: null,
    paginate: false,
    max_per_page: 50,
    list_query_params: {},
};

export default ListView;


const List = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    gap: 5px;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.6);
    width: 95%;
    margin: 5px 0px 0px 0px;
    overflow: auto;
    `;

const ListItem = styled.div`
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.attrCount}, minmax(200px, 500px))`};
    grid-gap: 0px;
    justify-items: left;
    align-items: left;
    background-color: rgba(200, 200, 200, 0.6);
    margin: auto;
    min-width: 100%;
    padding: 3px 20px;
    vertical-align: middle;
    `;

const FilterRow = styled(ListItem)`
    background-color: rgba(0,0,0,0);
    `;

const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    `;

const ActionButton = styled(Button)`
    margin-right:3px;
    min-width: 60px;
`;

const StyledLink = styled(Link)`
    color: blue !important;
    text-decoration: underline !important;
`;
