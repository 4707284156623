import React, { useState, useEffect, useRef } from "react";
import { Navigate, Link, useSearchParams } from "react-router-dom";

const MoreInfoRedirect = (props) => {

    //This component should display a thank you message and then redirect to the welcome page
    //with a countdown
    const [searchParams, setSearchParams] = useSearchParams();
    const [redirect, setRedirect] = useState(false);
    const [redirectTime, setRedirectTime] = useState(5);

    useEffect(() => {
        //wait 5 seconds and then redirect to the welcome page
        setTimeout(() => {
            setRedirect(true);
        }, 5000);
        let interval = setInterval(function() {
            setRedirectTime((redirectTime) => redirectTime - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [])



    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}
        
        >
            <h1>Thank you for your interest in Orchid!</h1>
            <h2>Our team will be in touch shortly.</h2>
            <img src="/images/orchid_logo_nobkg.png" alt="Orchid logo" height='100px'/>
            <p>You will be redirected to the welcome page in {redirectTime} seconds.</p>
            {redirect && <Navigate to='/welcome' />}
        </div>
    )

}

export default MoreInfoRedirect;