import {get, post, patch, del} from './common.js';


// Agreements
export function getAgreementsAdmin(query_params={}) {
    return get('/agreements/', 'admin', query_params=query_params)
}

export function getAgreementAdmin(agreement_id) {
    return get('/agreements/' + agreement_id + '/', 'admin')
}

export function createAgreementAdmin(data) {
    return post('/agreements/', data, 'admin')
}

export function updateAgreementAdmin(agreement_id, data) {
    return patch('/agreements/' + agreement_id + '/', data, 'admin')
}

// Agreement Versions
export function getAgreementVersionsAdmin(query_params={}) {
    return get('/agreement_versions/', 'admin', query_params=query_params)
}

export function getAgreementVersionAdmin(agreement_version_id) {
    return get('/agreement_versions/' + agreement_version_id + '/', 'admin')
}

export function createAgreementVersionAdmin(data) {
    return post('/agreement_versions/', data, 'admin')
}

export function updateAgreementVersionAdmin(agreement_version_id, data) {
    return patch('/agreement_versions/' + agreement_version_id + '/', data, 'admin')
}
