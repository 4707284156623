import React, { useEffect, useState, useRef} from 'react';
import { getGroups } from '../services/api/groups';
import { getDMs, } from '../services/api/dms';
import ChatArea from './ChatArea';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import UserIcon from './UserIcon';
import mixpanel from 'mixpanel-browser';
import NewChatArea from './NewChatArea';
import { useSearchParams } from 'react-router-dom';
import { patchGroupMember } from '../services/api/groups';
import useWindowSize from '../hooks/useWindowSize';
import MobileHeader from './MobileHeader';

const GroupsList = ({ groups, activeGroup, setActiveGroup }) => {
    const userSelf = useSelector(state => state.user.value);
    function dmName(members) {
        const names = members.filter(
            member => member.user.id != userSelf.id
        ).map(member => {
            if (member.user.first_name && member.user.last_name)
                return member.user.first_name + ' ' + member.user.last_name;
            else
                return member.user.email;
        }
        );
        if (names.length == 1) {
            return names[0];
        } else if (names.length == 2){
            return names[0] + ' and ' + names[1];
        } else if (names.length == 3){
            return names[0] + ', ' + names[1] +  ' + 1';
        } else{
            return names[0] + ', ' + names[1] +  ' + ' + (names.length - 2) ;
        }
    }

    function setCurrentGroupAsRead(group) {
        const chatLastViewedTimestamp = group.membership.chat_last_viewed_timestamp;
        const latestMessageTimestamp = group.latest_message_timestamp;
        if (chatLastViewedTimestamp < latestMessageTimestamp) {
            patchGroupMember(group.id, group.membership.id, {
                chat_last_viewed_timestamp: latestMessageTimestamp
            }).then(() => {
                mixpanel.track('Chat Viewed', {
                    'Group ID': group.id,
                    'Group Name': group.name,
                    'User ID': group.membership.user_id,
                });
            });
        }
    }


    return (
        <div>
            {groups.map((group, index) => (
                <GroupItem
                    key={index}
                    onClick={() => {setActiveGroup(group.id); setCurrentGroupAsRead(group);}}
                    isActive={activeGroup==group.id}
                    isUnread={group.latest_message_timestamp > group.membership.chat_last_viewed_timestamp}
                >
                    {!group.is_dm ?
                        (<><img src={group.avatar_url} width='30' onError={(e) => e.target.src='/images/icon_people.svg'} />
                        <span>{group.name}</span></>) :
                        (<>
                        <UserIcon user={group.members.filter(member => member.user.id != userSelf.id)[0].user} size={30}/> 
                        <span>{dmName(group.members)}</span></>)
                    }
                </GroupItem>
            ))}
        </div>
    );
}

const Messages = () => {

    const [ groups, setGroups ] = useState([]);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const activeGroup = searchParams.get('group_id');
    const setActiveGroup = (g) => {
        setSearchParams({group_id: g});
    };
    const windowSize = useWindowSize();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

    //useEffect to get groups
    useEffect(() => {

        //get groups and DMS
        Promise.all([
            getGroups({"order_by": "latest_message_timestamp", "order_type": "descending"}),
            getDMs({"order_by": "latest_message_timestamp", "order_type": "descending"}),
        ]).then(([groupItems, dmItems]) => {
            setGroups([...groupItems, ...dmItems]);
            console.log('activeGroup', activeGroup)
            if (activeGroup !== null) 
                return;
            else if ( groupItems[0]?.id || dmItems[0]?.id )
                setActiveGroup((groupItems[0]?.id ? groupItems[0].id : dmItems[0].id));
            else
                setActiveGroup('new_message');
        });
    }
    , [searchParams]);

    //useEffect to put any new messages at the top of the list
    useEffect(() => {
        if (groups.length > 0) {
            const newGroups = groups.sort((a, b) => {
                return b.latest_message_timestamp - a.latest_message_timestamp;
            });
            setGroups(newGroups);
        }
    }
    , [groups]);

    return (
        <>
        {(windowSize.width > 900) ? 
        <Container>
        {groups &&
            <PageLayout>
                <SideBar>
                    <div>
                        <h4>Messages</h4>
                    </div>
                    <GroupItem 
                        onClick={() => {setActiveGroup('new_message')}}
                        isActive={activeGroup==='new_message'}
                    >
                        <PlusSymbol> + </PlusSymbol>
                        <span>New Message</span>
                    </GroupItem>
                    <GroupsList groups={groups} activeGroup={activeGroup} setActiveGroup={setActiveGroup} />
                </SideBar>
                {activeGroup &&
                <ChatContainer>
                    { activeGroup==='new_message' ? 
                        <NewChatArea groups={groups} setGroups={setGroups} setActiveGroup={setActiveGroup}/> 
                        : 
                        ((activeGroup != 'new_message' && groups.length > 0) &&
                        <ChatArea group_id={activeGroup} />)
                    }
                </ChatContainer>
                }
            </PageLayout>
        }
        </Container>
        :
        <>
        <MobileHeader text="Messages" />
        {isMobileMenuOpen ?
            <>
            <SideBar>
                <GroupItem 
                    onClick={() => {setActiveGroup('new_message'); setIsMobileMenuOpen(false)}}
                    isActive={activeGroup==='new_message'}
                >
                    <PlusSymbol> + </PlusSymbol>
                    <span>New Message</span>
                </GroupItem>
                <GroupsList groups={groups} activeGroup={activeGroup} setActiveGroup={(g) => {setActiveGroup(g); setIsMobileMenuOpen(false)}} />
            </SideBar>
            </>
            :
            <>
            <BackArrow onClick={() => setIsMobileMenuOpen(true)}> {'\u2039'} </BackArrow>
            {activeGroup &&
                <ChatContainer>
                    { activeGroup==='new_message' ? 
                        <NewChatArea groups={groups} setGroups={setGroups} setActiveGroup={setActiveGroup}/> 
                        : 
                        ((activeGroup != 'new_message' && groups.length > 0) &&
                        <ChatArea group_id={activeGroup} />)
                    }
                </ChatContainer>
                }
                </>
        }
        </>
        }
        </>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-width: 1920px;
`;

const PageLayout = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-top: 10px;
`;

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 245px;
    height: 100%;
    border-right: 1px solid #e6e6e6;
    overflow-y: scroll;
    flex-shrink: 0;

    @media (max-width: 899px) {
        width: 100%;
        height: calc(100vh - 100px);
    }

`;

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-items: center;
    padding-left: 10px;
`;

const GroupItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    background-color: ${props => props.isActive ? '#f5f5f5' : 'transparent'};
    font-weight: ${props => props.isUnread ? 'bold' : 'normal'};
    text-align: left;
    gap: 10px;
    img {
        margin-right: 0px;
    }

    @media (max-width: 899px) {
        background-color: ${props => props.isActive ? 'transparent' : 'transparent'};
    }
`;

const PlusSymbol = styled.div`
    font-size: 20px;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 50%;
    background-color: lightgray;
    color: white;
    width: 30px;
    height: 30px;
    margin-right: 0px;
`;

const BackArrow = styled.div`
    font-size: 40px;
    display: block;
    position: absolute;
    top: 40px;
    left: 30px;
    color: #666;
    cursor: pointer;

`;

export default Messages;
