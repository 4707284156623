import styled from "styled-components";

export const GlassContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: inherit;
    height: inherit;
    
    border-radius: 15px;
    box-shadow: inset 0px 0px 100px rgba(0, 0, 100, 0.05);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    `;