import React, { useState, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import Button from 'react-bootstrap/Button';
import { getComments, createComment, getShares, getCommentsByReplyTo, getTopLevelComments, getTopLevelCommentsByShare } from '../services/api/attachments';
import { ageFromTimestamp } from '../services/util/metadata';
import Collapse from 'react-bootstrap/Collapse'
import { parseTimestamps } from '../services/util/parsers';
import { getGroups } from "../services/api/groups.js";
import SymbolBubble from './SymbolBubble.js';
import UserIcon from './UserIcon.js';
import { Overlay, OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import mixpanel from 'mixpanel-browser';

const Reply = ({ attachment_id, share_id, parent_id, comment, playerRef, replies, setReplies, parentAuthor }) => {
  const [replyText, setReplyText] = useState('@' + parentAuthor + ' ');
  const [expandReplyBox, setExpandReplyBox] = useState(false);

  const calculateCommentAge = (comment) => {
    return ageFromTimestamp(comment.created_on);
  }

  const handleComment = (newComment) => {
    createComment(attachment_id, newComment).then(data => {
    setReplies([data, ...replies]);
  })};

  const handleReply = () => {
    if (replyText.trim() !== '') {
      const newComment = { text: replyText, share_id: share_id, reply_to_id: parent_id }
      handleComment(newComment);
      setReplyText('');
      setExpandReplyBox(!expandReplyBox);
    }
  };

  return(
  <CommentContainer>
      <p align="left" style={{ fontSize: '0.8rem', color: '#808080' }}>
        {comment.user.first_name} {comment.user.last_name}  {calculateCommentAge(comment)}
      </p>
      <p align="left">{parseTimestamps(comment.text, playerRef)}</p>
      <span>
      <Button variant="link" size="sm" onClick={() => setExpandReplyBox(!expandReplyBox)}>Reply</Button>
      </span>
      <Collapse in={expandReplyBox}>
        <CommentInputContainer>
          <ReplyInputBox
            type="text"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <Button onClick={handleReply} size="sm">Reply</Button>
        </CommentInputContainer>
      </Collapse>
    </CommentContainer>
  );
};

const Comment = ({ attachment_id, share_id, comment, playerRef, commentCount, setCommentCount}) => {
  const [replyText, setReplyText] = useState('');
  const [expandReplies, setExpandReplies] = useState(false);
  const [expandReplyBox, setExpandReplyBox] = useState(false);
  const [replies, setReplies] = useState([]);

  useEffect(() => {
    getCommentsByReplyTo(attachment_id, comment.id).then(data => {
      setReplies(data.reverse());
    });
  }, [comment.id]);

  const handleComment = (newComment) => {
    createComment(attachment_id, newComment).then(data => {
    setReplies([ data, ...replies]);
  })};

  const handleReply = () => {
    if (replyText.trim() !== '') {
      const newComment = { text: replyText, share_id: share_id, reply_to_id: comment.id }
      handleComment(newComment);
      setReplyText('');
      setExpandReplyBox(!expandReplyBox);
      setCommentCount(commentCount + 1);
    }
  };

  const calculateCommentAge = (comment) => {
    return ageFromTimestamp(comment.created_on);
  }

  return (
      <CommentContainer>
        <p align="left" style={{ fontSize: '0.8rem', color: '#808080' }}>
          {comment.user.first_name} {comment.user.last_name}  {calculateCommentAge(comment)}
        </p>
        <p align="left">{parseTimestamps(comment.text, playerRef)}</p>
        <span>
        <Button variant="link" size="sm" onClick={() => setExpandReplyBox(!expandReplyBox)}>Reply</Button>
        {replies.length>0 && <Button variant="link" size="sm" onClick={() => setExpandReplies(!expandReplies)}>
          {replies.length} {replies.length === 1 ? 'Reply' : 'Replies'} 
          <span>{expandReplies ? `\u25B4` : `\u25BE`}</span>
        </Button>}
        </span>
        <Collapse in={expandReplyBox}>
          <CommentInputContainer>
            <CommentInputBox
              type="text"
              placeholder="Reply..."
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              onFocus={(e) => {e.target.placeholder='To link to a specific time, type @mm:ss...'}}
              onBlur={(e) => {e.target.placeholder='Reply...'}}
            />
            <Button onClick={handleReply} size="sm">Reply</Button>
          </CommentInputContainer>
        </Collapse>
        <Collapse in={expandReplies}>
          <RepliesContainer>
            {replies.map((reply) => (
              <Reply
                key={reply.id}
                attachment_id={attachment_id}
                share_id={share_id}
                parent_id={comment.id}
                comment={reply}
                playerRef={playerRef}
                replies={replies}
                setReplies={setReplies}
                parentAuthor={comment.user.first_name}
              />
            ))}
          </RepliesContainer>
        </Collapse>
      </CommentContainer>
  );
};


const CommentsByShare = ({attachment_id, name, share_id, playerRef, commentCount, setCommentCount}) => {
  const [newCommentText, setNewCommentText] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getTopLevelCommentsByShare(attachment_id, share_id).then(data => {
      console.log('share id', share_id)
      console.log('comments', data)
      setComments(data.reverse());
    });
  }, [share_id]);

  const handleComment = (newComment) => {
    createComment(attachment_id, newComment).then(data => {
    setComments([data, ...comments]);
  })};

  const handlePostComment = () => {
    if (newCommentText.trim() !== '') {
      const newComment = {
        text: newCommentText, 
        share_id: share_id,
        reply_to_id: null
      };     
      setNewCommentText('');
      handleComment(newComment);
      setCommentCount(commentCount + 1);
    }
  };


  return(
    <CommentsArea key={share_id}>
    <CommentInputContainer>
      <CommentInputBox
        type="text"
        placeholder="Add Comment..."
        value={newCommentText}
        onChange={(e) => setNewCommentText(e.target.value)}
        onFocus={(e) => {e.target.placeholder='To link to a specific time, type @mm:ss...'}}
        onBlur={(e) => {e.target.placeholder='Add Comment...'}}
      />
      <Button onClick={handlePostComment} size="sm">Post</Button>
    </CommentInputContainer>
    {playerRef && comments.map((comment) => (
      <Comment 
        key={comment.id} 
        share_id={share_id}
        attachment_id={attachment_id} 
        comment={comment} 
        playerRef={playerRef}
        commentCount={commentCount}
        setCommentCount={setCommentCount}
        />
    ))}
  </CommentsArea>
  );
}

const CommentSection = ({attachment_id, playerRef, comment_count}) => {
  //states and references
  const [groups, setGroups] = useState([]);
  const [shares, setShares] = useState([]);
  const [groupShares, setGroupShares] = useState();
  const [commentCounts, setCommentCounts] = useState([comment_count]);

  useEffect(() => {
    // get the user's groups
    getGroups()
    .then((data) => {
      console.log('groups', data)
      setGroups(data);
    })

    // get the shares
    getShares(attachment_id)
    .then((data) => {
      console.log('shares', data)
      setShares(data);
    })
  }, [attachment_id]);

  useEffect(() => {
    //if a share has a group_id that is in the user's groups, add it to the shareGroups
    let shareGroups = shares.filter((share) => groups.some((group) => group.id === share.group_id));
    console.log('shareGroups', shareGroups)
    setGroupShares([...shareGroups, {id: null}]);
    setCommentCounts([ ...shareGroups.map((share) => share.comment_count), comment_count ]);
    setActiveCommentShare(0);
  }, [shares, groups, attachment_id])

  const [activeCommentShare, setActiveCommentShare] = useState(0);

  return (
    <>
    {groupShares && 
    <CommentsArea>
      <CommentSelectionBar>
        {groupShares.map((share, idx) => {
          let active = idx === activeCommentShare;
          return(
            <CommentSelector active={active} key={idx} onClick={() => setActiveCommentShare(idx)}>
              {share.id ?
              <OverlayTrigger
                key={idx}
                placement={'top'}
                delay={{ show: 750 }}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {`Comments will only be visible to members of this group.`}
                  </Tooltip>
                }
              >
              <span> 
                {active ? <img src="/images/icons/comment_blue.svg"  width='16px'/> : <img src="/images/icons/comment_black.svg"  width='16px'/>}
                 <span>{' ' + groups.find((group) => group.id === share?.group_id)?.name + ' • ' + commentCounts[idx] + ' '}</span>
              </span>
              </OverlayTrigger>:
              <OverlayTrigger
                key={idx}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {`Comments will be visible to anyone who shares access to this video via DMs.`}
                  </Tooltip>
                }
              >
              <span>
                {active ? <img src="/images/icons/comment_blue.svg"  width='16px'/> : <img src="/images/icons/comment_black.svg"  width='16px'/>}
                <span>{' Direct Shares • ' + commentCounts[idx]} </span>
              </span>
              </OverlayTrigger>
              }
            </CommentSelector>
          )
        })}
        
      </CommentSelectionBar>
    <CommentsByShare
      attachment_id={attachment_id}
      name={groups.find((group) => group.id === groupShares[activeCommentShare].group_id)?.name}
      key={groupShares[activeCommentShare].id}
      share_id={groupShares[activeCommentShare].id}
      playerRef={playerRef}
      commentCount={commentCounts[activeCommentShare]}
      setCommentCount={(n) => setCommentCounts([ ...commentCounts.slice(0, activeCommentShare), n, ...commentCounts.slice(activeCommentShare + 1) ])}
    />
    </CommentsArea>
    }
    </>
  );
};

const CommentsArea = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 150px;

  h5 {
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
  }

  .comment-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  p {
    margin: 2px;
  }
  `;

const RepliesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  width: 100%;
  max-width: calc(100% - 20px);
`;

const CommentInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  padding: 5px 5px 5px 10px;

  &:focus-within {
    background-color: #f5f5f5;
  }

`;

const CommentInputBox = styled.input`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 32px;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  outline: none;
  display: flex;
  align-items: flex-start;

`;

const ReplyInputBox = styled.input`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  height: 32px;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  outline: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CommentSelectionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #d4d4d4;
  font-weight: bold;
  `

const CommentSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 7px 12px;
  cursor: pointer;
  width: fit-content;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  ${props => props.active && `
    color: #007bff;
    border-bottom: 2px solid #007bff;
  `}
  `

export default CommentSection;