import styled from 'styled-components';
import { useState, useEffect } from 'react';
import DetailView from './DetailView.js';
import { useParams } from 'react-router-dom';
import { getOrganizationUserAdmin, updateOrganizationUserAdmin, deleteOrganizationUserAdmin } from '../services/api/admin_organizations.js';


const AdminOrganizationUser = (props) => {

    const { organization_id, member_id } = useParams();

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false
        },
        {
            "key": "organization_id",
            "label": "Organization ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "user_id",
            "label": "User ID",
            "type": "copy",
            "settable": false,
        },
        {
            "key": "user.email",
            "label": "Email",
            "type": "string",
            "settable": false,
        },
        {
            "key": "is_admin",
            "label": "Admin?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "is_sponsored",
            "label": "Sponsored?",
            "type": "boolean",
            "settable": true,
        }
    ];

    return (
        <Page>
            <DetailView
                title="Admin: Organization Member"
                get_method={getOrganizationUserAdmin}
                update_method={updateOrganizationUserAdmin}
                delete_method={deleteOrganizationUserAdmin}
                attributes={attributes}
                primary_key={member_id} />
        </Page>
    );

};

export default AdminOrganizationUser;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
