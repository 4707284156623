
export function parseMediaManifest(playlistText) {
    const lines = playlistText.split('\n');
    const segments = [];
    let currentSegment = null;
    let header = '';
    let segmentID = 0;
    let currentTime = 0;
  
    lines.forEach(line => {
      if (line && line.startsWith('#EXTINF')) {
        currentSegment = { duration: parseFloat(line.split(':')[1]), uri: null, byteRange: null, startTime: currentTime };
        currentTime += currentSegment.duration;
      } else if (line && line.startsWith('#EXT-X-BYTERANGE')) {
        currentSegment.byteRange = line.split(':')[1];
      } else if (line && line.startsWith('#EXT-X-ENDLIST')) {
        //ignore
      } else if (line && line.startsWith('#EXT')) {
        header += `${line}\n`;
      } else if (line && !line.startsWith('#')) {
        if (currentSegment) {
          currentSegment.uri = line;
          currentSegment.segmentID = segmentID;
          segments.push(currentSegment);
          currentSegment = null;
          segmentID++;
        }
      }
    });
  
    return { header, segments };
}

export function cutSegments(segments, startTime, endTime) {

    //filter out segments that are within the cut range, inclusive of endpoints
    return segments.filter(segment => {
        return !(segment.startTime + segment.duration > startTime && segment.startTime < endTime);
    });
}

export function generateMediaManifest(header, segments) {
  let playlist = header;
  segments.forEach((segment, idx) => {
      //if the segment is not the first segment and the segment index has incremented by more than 1, add a discontinuity
      if (idx > 0 && segment.segmentID - segments[idx - 1].segmentID > 1)
        playlist += '#EXT-X-DISCONTINUITY\n';
      playlist += `#EXTINF:${segment.duration}\n`
      if (segment.byteRange)
        playlist += `#EXT-X-BYTERANGE:${segment.byteRange}\n`
      playlist += `${segment.uri}\n`;
  });
  playlist += '#EXT-X-ENDLIST';
  return playlist;
}

export function prependBaseURI(playlistText, baseURI) {
    // for every uri in the playlist, prepend the uri with the base uri
    const lines = playlistText.split('\n');
    let newPlaylist = '';
    lines.forEach(line => {
      //if the line contains URI="...", prepend the base uri to the word attachment
      if (line.includes('URI="')) {
        const uri = line.split('URI="')[1].split('"')[0];
        newPlaylist += `${line.replace(uri, `${baseURI}${uri}`)}\n`;
      } else
      //if the line is not a tag, prepend the base uri
      if (line && !line.startsWith('#')) {
        newPlaylist += `${baseURI}${line}\n`;
      } else {
        newPlaylist += `${line}\n`;
      }
    });
    return newPlaylist;
}

export function combineByteranges(segments, maxLength) {
    let currentSegment = null;
    let combinedSegments = [];
    let combinedSegmentID = 0;

    //combine every n segments into a single section covering the total byterange
    segments.forEach(segment => {
        segment.byteOffset = Number(segment.byteRange.split('@')[1]) || 0;
        segment.byteLength = Number(segment.byteRange.split('@')[0]);
        
        if (currentSegment) {
            currentSegment.duration += segment.duration;
            currentSegment.byteLength += segment.byteLength;
        } else {
            currentSegment = { ...segment };
        }
        if (currentSegment.duration >= maxLength) {
            currentSegment.byteRange = `${currentSegment.byteLength}@${currentSegment.byteOffset}`;
            currentSegment.segmentID = combinedSegmentID;
            combinedSegmentID++;
            combinedSegments.push(currentSegment);
            // console.log(combinedSegments)
            currentSegment = null;
        }
    });
    return combinedSegments;
}

export function parseThumbnailManifest(thumbnailPlaylist) {
    const lines = thumbnailPlaylist.split('\n');
    const thumbnails = [];
    const durations = [];
    let currentThumbnail = null;

    lines.forEach(line => {
        if (line && line.startsWith('#EXTINF')) {
            // currentThumbnail = { duration: parseFloat(line.split(':')[1]), uri: null};
            currentThumbnail = { uri: null, startTime: null}
            durations.push(parseFloat(line.split(':')[1]));
        } else if (line && line.startsWith('#EXT-X-ENDLIST')) {
            //ignore
        } else if (line && line.startsWith('#EXT')) {
            //ignore
        } else if (line && !line.startsWith('#')) {
            if (currentThumbnail) {
                currentThumbnail.uri = line;
                //start time is the sum of all previous durations excent the last one
                currentThumbnail.startTime = durations.reduce((a, b) => a + b) - durations[durations.length - 1];
                currentThumbnail.duration = durations[durations.length -1];
                thumbnails.push(currentThumbnail);
                currentThumbnail = null;
            }
        }
    }
    );

    return thumbnails;
}

export function parseMasterManifest(masterManifest) {
    const lines = masterManifest.split('\n');
    const header = [];
    const streams = [];
    let currentStream = null;

    lines.forEach(line => {
        if (line && line.startsWith('#EXT-X-STREAM-INF')) {
            currentStream = { uri: null, parameters: null, type: 'video'};
            currentStream.uri = lines[lines.indexOf(line) + 1];
            currentStream.parameters = line.split(':')[1];
            streams.push(currentStream);
        }
        if (line && line.startsWith('#EXT-X-MEDIA')) {
            currentStream = { uri: null, parameters: null, type: 'audio'};
            currentStream.uri = line.split('URI="')[1].split('"')[0];
            currentStream.parameters = line.split(':')[1].replace(/URI=".*"/, "");
            streams.push(currentStream);
        }
        if (line && line.startsWith('#EXT-X-IMAGE-STREAM-INF')) {
            currentStream = { uri: null, parameters: null, type: 'image'};
            currentStream.uri = line.split('URI="')[1].split('"')[0];
            currentStream.parameters = line.split(':')[1].replace(/URI=".*"/, "");
            streams.push(currentStream);
        }
    });

    return streams;
}

export function generateMasterManifest(streams) {
    let masterManifest = '#EXTM3U\n#EXT-X-VERSION:6\n#EXT-X-INDEPENDENT-SEGMENTS\n';
    streams.forEach(stream => {
      if (stream.type === 'video')
        masterManifest += `#EXT-X-STREAM-INF:${stream.parameters}\n${stream.uri}\n`;
      else if (stream.type === 'audio')
        masterManifest += `#EXT-X-MEDIA:${stream.parameters}URI="${stream.uri}"\n`;
      else if (stream.type === 'image')
        masterManifest += `#EXT-X-IMAGE-STREAM-INF:${stream.parameters}\n${stream.uri}\n`;
    });

    return masterManifest;
}

