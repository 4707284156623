import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import TOSModal from "./TOSModal";
import {useSelector, useDispatch} from 'react-redux';

const ProtectedRoute = ({
    redirectPath = '/login',
    children,
  }) => {

    const user = useSelector(state => state.user.value);
    const location = useLocation();
    
    if (!user) {
      return <Navigate to={redirectPath} state={{ from: location.pathname, params: location.search}}/>;
    } 
    else if (user && user.has_outstanding_agreements) {
      return <TOSModal user={user}/>;
    }
    return children ? children : <Outlet />;
  };

export default ProtectedRoute;