import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SymbolBubble from './SymbolBubble.js';
import Button from 'react-bootstrap/Button';
import { releaseHijack } from '../services/api/admin_users.js';
import { useNavigate } from 'react-router-dom';


const SimulateWarning = ({setUser}) => {
    let navigate = useNavigate();

    const handleReleaseHijack = () => {
        releaseHijack().then((data) => {
            localStorage.removeItem('isHijacked');
            setUser(data);
            window.location.replace('/home');
        });
    }

    return (
        <SimulateWarningDiv>
            {(localStorage.getItem('isHijacked') === "true") ? (
                <ShowSimulated>
                    <b>SIMULATED SESSION</b>
                    <SymbolBubble
                        variant="warning"
                        text="This is a simulated session! Any action taken will alter data for this user."
                    />
                    &nbsp;&nbsp;
                    <Button onClick={handleReleaseHijack} variant="outline-light">Release</Button>
                </ShowSimulated>
            ) : (
                <div></div>
            )}
        </SimulateWarningDiv>
    )
}

export default SimulateWarning;

const SimulateWarningDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: fill;
`;

const ShowSimulated = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: fill;
    background-color: red;
    padding: 10px;
    color: white;
    border-radius: 5px;
`;
