import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div.attrs(({ 
    $containerWidth, 
    $containerHeight,
    $totalWidth,
    $totalHeight,
    $left,
    $top,
    $src
  }) => ({
  style: {
    width: `${$containerWidth}px`,
    height: `${$containerHeight}px`,
    backgroundSize: `${$totalWidth}px ${$totalHeight}px`,
    backgroundPosition: `-${$left}px -${$top}px`,
    backgroundImage: `url(${$src})`,
    flex: `0 0 ${$containerWidth}px`,
  },
}))`
  display: inline-flex;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-clip: content-box;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

const Thumbnail = ({ src, n, m, k, totalWidth, totalHeight, width, height }) => {
  // Calculate the position of the kth tile (0-based index)
  const rowIndex = Math.floor(k / m);
  const columnIndex = k % m;    

  const left = columnIndex * width;
  const top = rowIndex * height;

  return (
    <ImageContainer
      $src={src}
      $totalWidth={m*width}
      $totalHeight={n*height}
      $left={left}
      $top={top}
      $containerWidth={width}
      $containerHeight={height}
    />
  );
};

export default Thumbnail;