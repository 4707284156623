import styled from 'styled-components';
import ListView from './ListView.js';
import NewEntry from './NewEntry.js';
import { createTagAdmin, getTagsAdmin, deleteTagAdmin } from '../services/api/admin_tags.js';
import { specialization_enum } from '../enums/specialization.js';
import { tag_type_enum } from '../enums/tag_type.js';


const AdminTags = (props) => {

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "tag_name",
            "label": "Tag Name",
            "type": "string",
            "filterable": true,
            "detailLink": "/admin/tags/<id>",
            "settable": true,
            "required": true,
        },
        {
            "key": "tag_specialization",
            "label": "Tag Specialization",
            "type": "choice",
            "filterable": true,
            "settable": true,
            "required": false,
            "choices": specialization_enum,
            "tooltip": {
                "variant": "info",
                "text": "This tag will only be settable on cases with this specialization."
            }
        },
        {
            "key": "tag_type",
            "label": "Tag Type",
            "type": "choice",
            "filterable": true,
            "settable": true,
            "choices": tag_type_enum,
        },
        {
            "key": "is_public",
            "label": "Public?",
            "type": "boolean",
            "filterable": true,
            "settable": true,
            "required": false,
            "tooltip": {
                "variant": "warning",
                "text": "If this tag is marked as public, it will be visible to all users."
            }
        },
        {
            "key": "owner_id",
            "label": "Owner ID",
            "type": "copy",
            "filterable": true,
            "settable": true,
            "required": false,
            "tooltip": {
                "variant": "info",
                "text": "The user ID of the owner of this tag. This tag will only be visible to this user, unless the tag is marked as public."
            }
        }
    ]

    let new_attributes = [
        ...attributes,
        {
            "key": "tag_description",
            "label": "Tag Description",
            "type": "text",
            "settable": true,
            "required": false,
        },
    ]

    return (
        <Page>
            <ListView
                title="Admin: Tags"
                list_method={getTagsAdmin}
                delete_method={deleteTagAdmin}
                attributes={attributes}
                paginate={true}
                max_per_page={50} />
            <NewEntry
                title="Admin: Tags"
                create_method={createTagAdmin}
                attributes={new_attributes} />
        </Page>
    )

};

export default AdminTags;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
