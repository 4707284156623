import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';


const MfaInput = (props) => {

    const [formattedValue, setFormattedValue] = useState("");

    const handleInput = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        // stop at 6 characters
        if (input.length > 6) {
            input = input.slice(0, 6);
        }
        let formatted = "";
        for (let i = 0; i < input.length; i++) {
            if (i === 3 || i === 6) {
                formatted += "-";
            }
            formatted += input[i];
        }
        setFormattedValue(formatted);
        props.onChange(input);
    }

    return (
        <Form.Group>
            <Form.Control
                type="text"
                onChange={handleInput}
                placeholder="###-###"
                value={formattedValue}
            />
        </Form.Group>
    )

}

export default MfaInput;

MfaInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};
