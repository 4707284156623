import {get, post, patch, del} from './common.js';

export function getUsersAdmin(query_params={}) {
    return get('/users/', 'admin', query_params);
}

export function getUserAdmin(id) {
    return get('/users/' + id + '/', 'admin');
}

export function createUserAdmin(data) {
    return post('/users/', data, 'admin');
}

export function updateUserAdmin(id, data) {
    return patch('/users/' + id + '/', data, 'admin');
}

export function deleteUserAdmin(id) {
    return del('/users/' + id + '/', 'admin');
}

export function inviteUser(user_id) {
    return post('/invite/', {"user_id": user_id}, 'admin');
}

export function hijackUser(id) {
    return post('/hijack/', {'user_id': id}, 'admin');
}

export function releaseHijack() {
    return del('/hijack/', 'admin');
}
