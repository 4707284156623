import React, { useState, useEffect } from 'react';
import { getMe, updatePassword, updateUser, disableMFA } from '../services/api/me';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import SymbolBubble from './SymbolBubble';
import EnableMfaModal from './EnableMfaModal';
import SubscriptionStatus from './SubscriptionStatus';

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [userDataChanged, setUserDataChanged] = useState(null);
    const [profileUpdateMessage, setProfileUpdateMessage] = useState(null);
    const [pwData, setPwData] = useState(null);
    const [passwordMessage, setPasswordMessage] = useState(null);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [showMfaModal, setShowMfaModal] = useState(false);

    useEffect(() => {
        // Fetch user data
        getMe().then((data) => {
            setUserData(data);
        });
    }, []);

    const handleMfaClick = (e) => {
        if (e.target.checked) {
            setShowMfaModal(true);
        } else {
            disableMFA().then(() => {
                setUserData({...userData, mfa_enabled: false});
            });
        }
    }

    const successMfa = () => {
        setUserData({...userData, mfa_enabled: true});
        setShowMfaModal(false);
    }

    const handleInputChange = (e) => {
        // Update user data when input values change
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
        setUserDataChanged({...userDataChanged,
            [e.target.name]: true});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!userDataChanged) {
            setProfileUpdateMessage('No changes to save');
            return;
        }

        let dataToUpload={};
        for (const [key, value] of Object.entries(userData)) {
            if (userDataChanged[key]) {
                dataToUpload[key] = value;
            }
        }
        updateUser(dataToUpload).then((data) => {
            if (!data) { 
                setProfileUpdateMessage('Error updating profile');
            } else {
                setUserData(data);
                setUserDataChanged(null);
                setProfileUpdateMessage('Profile successfully updated');
            }
        })
    };

    const handlePasswordChange = (e) => {
        // Update user data when input values change
        setPwData({
            ...pwData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitPassword = (e) => {
        e.preventDefault();

        updatePassword(pwData.current_password, pwData.new_password).then((data) => {
            if (!data) { 
                setPasswordMessage('Current password is incorrect');
            } else {
                setPwData({
                    current_password: '',
                    new_password: '',
                    confirm_new_password: ''
                });
                setPasswordMessage('Password successfully updated');
            }
        }
    )};

    const validatePassword = () => {
        let pwErrors = [];

        if (pwData?.new_password) {
            // check password meets requirements
            if (pwData?.new_password.length < 8) {
                pwErrors.push('Password must be at least 8 characters long.');
            }
            if (!pwData?.new_password.match(/[A-Z]/)) {
                pwErrors.push('Password must contain at least one uppercase letter.');
            }
            if (!pwData?.new_password.match(/[a-z]/)) {
                pwErrors.push('Password must contain at least one lowercase letter.');
            }
            if (!pwData?.new_password.match(/[0-9]/)) {
                pwErrors.push('Password must contain at least one number.');
            }
            if (!pwData?.new_password.match(/[!@#$%^&\*]/)) {
                pwErrors.push('Password must contain at least one special character.');
            }

            setPasswordErrors(pwErrors);
        }

        if (pwData?.confirm_new_password) {
            // check passwords match
            if (pwData?.new_password !== pwData?.confirm_new_password) {
                setPasswordMatch(false);
            } else {
                setPasswordMatch(true);
            }
        }
    }

    //useEffect to validate passwords
    useEffect(() => {
        validatePassword();
    }, [pwData])

    return (
        <PageContainer>
            {userData ? (
                <>
                <Form>
                    <Container>
                    <p>
                        <b>My Info </b> 
                        <SymbolBubble
                            text="Some data will be visible to colleagues when you share cases with them. We will never share your email, phone number, or address without your consent."
                            variant="info"
                        />
                    </p>
                    <Row className="justify-content-md-left">
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Select name="prefix" type="email" value={userData.prefix} onChange={handleInputChange}>
                            <option value=''></option>
                            <option value="Dr.">Dr.</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                        </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Control name="first_name" type="text" value={userData.first_name} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Middle Name</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Control name="middle_name" type="text" value={userData.middle_name} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Control name="last_name" type="text" value={userData.last_name} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Suffix</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Control name="suffix" type="text" value={userData.suffix} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name="email" type="email" disabled value={userData.email} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Form.Group>
                        <Form.Label>Specialization</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Select name="specialization" value={userData.specialization?.toUpperCase()} onChange={handleInputChange}>
                            {/* <option value=''>None</option> */}
                            <option value="GENERAL">General Surgery</option>
                            <option value="ORTHOPEDIC">Orthopedic Surgery</option>
                            <option value="CARDIOTHORACIC">Cardiothoracic Surgery</option>
                            <option value="NEUROSURGERY">Neurosurgery</option>
                            <option value="PLASTIC">Plastic Surgery</option>
                            <option value="VASCULAR">Vascular Surgery</option>
                            <option value="UROLOGY">Urological Surgery</option>
                            <option value="PEDIATRIC">Pediatric Surgery</option>
                            <option value="COLORECTAL">Colorectal Surgery</option>
                            <option value="OPTHALMOLOGY">Opthalmologic Surgery</option>
                            <option value="OTOLARYNGOLOGY">Otolaryngologic Surgery</option>
                            <option value="GYNECOLOGIC">Gynecologic Surgery</option>
                            <option value="ORAL_MAXILLOFACIAL">Oral and Maxillofacial Surgery</option>
                            <option value="TRANSPLANT">Transplant Surgery</option>
                            <option value="BARIATRIC">Bariatric Surgery</option>
                            <option value="TRAUMA">Trauma Surgery</option>
                            <option value="HAND">Hand Surgery</option>
                            <option value="ONCOLOGY">Oncological Surgery</option>
                            <option value="THORACIC">Thoracic Surgery</option>
                            <option value="HEPATOBILIARY">Hepatobiliary Surgery</option>
                        </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Practice/Institute/Org</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Control name="company" type="text" value={userData.company} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control name="address" type="text" value={userData.address} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control name="city" type="text" value={userData.city} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control name="state" type="text" value={userData.state} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Zipcode</Form.Label>
                        <Form.Control name="zipcode" type="text" value={userData.zipcode} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Country</Form.Label>
                        <SymbolBubble
                            text="This will be visible as part of your public profile."
                            variant="public"
                        />
                        <Form.Control name="country" type="text" value={userData.country} onChange={handleInputChange}/>
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                Save
                            </Button>
                            <span> {profileUpdateMessage}</span>
                        </Col>
                    </Row>
                </Container>
                </Form>
            <br/><br/>
            <Form>
                <Container>
                    <p>
                        <b>Change Password </b>
                        <SymbolBubble
                            text="Your password must be at least 8 characters long."
                            variant="info"
                        />
                    </p>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control name="current_password" type="password" value={pwData?.current_password} onChange={handlePasswordChange}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control name="new_password" type="password" value={pwData?.new_password} onChange={handlePasswordChange}/>
                                { pwData?.new_password ? (
                                <div>
                                    <ErrorList>
                                    {passwordErrors.map((error, index) => (
                                        <ErrorItem key={index}>{error}</ErrorItem>
                                    ))}
                                    </ErrorList>
                                </div>
                                ) : (null)}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control name="confirm_new_password" type="password" value={pwData?.confirm_new_password} onChange={handlePasswordChange}/>
                                { pwData?.confirm_new_password && !passwordMatch ? (
                                <div>
                                    <ErrorList>
                                        <ErrorItem>Passwords do not match.</ErrorItem>
                                    </ErrorList>
                                </div>
                                ) : (null)}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" disabled={!passwordErrors.length === 0 || !passwordMatch || !pwData?.current_password} type="submit" onClick={handleSubmitPassword}>
                                Save
                            </Button>
                            <span> {passwordMessage}</span>
                        </Col>
                    </Row>
                </Container>
            </Form>
            <br /><br />

            <Form>
                <Container>
                    <p>
                        <b>Multi-Factor Authentication</b>
                        <SymbolBubble
                            text="Enable MFA to add an extra layer of security to your account."
                            variant="info"
                        />
                    </p>
                    <Row>
                        <Col>
                            <Form.Check type="switch" id="mfa-switch" label="Enable MFA" checked={userData.mfa_enabled} onClick={handleMfaClick} />
                        </Col>
                    </Row>
                    <EnableMfaModal show={showMfaModal} onHide={() => setShowMfaModal(false)} onSuccess={successMfa} />
                </Container>
            </Form>
            <br /><br />

            <Form>
                <Container>
                    <p>
                        <b>Account</b>
                        <SymbolBubble
                            text="Your account tier determines which features you can access."
                            variant="info"
                        />
                    </p>
                    <SubscriptionStatus userData={userData} />
                </Container>
            </Form>
            </>
            ) : (
                <p>Loading user data...</p>
            )}
        </PageContainer>
    );
};

export default Profile;

const PageContainer = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    margin-top: 80px;
    text-align: left;
`;

const ErrorList = styled.ul`
    list-style-type: none;
    padding-top: 5px;
    font-size: 0.8rem;
`;

const ErrorItem = styled.li`
    list-style-type: '❌ ';
    color: red;
`;
