import styled from 'styled-components';
import ListView from '../components/ListView.js';
import { createGroupAdmin, getGroupsAdmin, deleteGroupAdmin } from '../services/api/admin_groups.js';
import NewEntry from './NewEntry.js';


const AdminGroups = (props) => {
    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "owner_id",
            "label": "Owner ID",
            "type": "copy",
            "filterable": true,
            "settable": true,
            "required": true,
        },
        {
            "key": "name",
            "label": "Name",
            "type": "string",
            "filterable": true,
            "detailLink": "/admin/groups/<id>",
            "settable": true,
            "required": true,
        },
        {
            "key": "is_public",
            "label": "Public?",
            "type": "boolean",
            "filterable": true,
            "settable": true,
            "required": false,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "filterable": true,
            "settable": false,
        },
        {
            "key": "created_on",
            "label": "Created At",
            "type": "string",
            "filterable": false,
            "settable": false,
        },
        {
            "key": "updated_on",
            "label": "Updated At",
            "type": "string",
            "filterable": false,
            "settable": false,
        }
    ];

    return (
        <Page>
            <ListView
                title="Admin: Groups"
                list_method={getGroupsAdmin}
                delete_method={deleteGroupAdmin}
                attributes={attributes}
                paginate={true}
                max_per_page={50} />
            <NewEntry
                title="Admin: Groups"
                create_method={createGroupAdmin}
                attributes={attributes} />
        </Page>
    )
}

export default AdminGroups;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
