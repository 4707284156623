import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BackgroundShapes, Panel, HeaderBackground, StyledLandingPage, MobileStyledLandingPage} from "./LandingPage";
import { GlassContainer } from "../components/GlassContainer";
import Form from '../components/Form';
import useScrollReporter from "../../hooks/useScrollReporter";

const ForMedTechs = () => {

    React.useEffect(() => {
        document.title = "Orchid Cloud | For MedTechs";
      }, []);
    useScrollReporter('For MedTechs')

    return (
        <>
        <MobileStyledLandingPage>
        <HeaderBackground>
            <img src="/images/store/ForMedTechs/header_bkg.svg" alt="Orchid Surgical" height ='100%'/>
        </HeaderBackground>
        <Header />
        <TitlePanel>
            <StyledText>
                <div style={{width: '100%', textAlign: 'left'}}>
                    <span style={{
                        color: '#303030', 
                        fontSize: 46, 
                        fontFamily: 'Lato', 
                        fontWeight: '600', 
                    }}
                    >
                        One Platform For Your Company To
                    </span>
                    <span style={{
                        color: '#4127AB', 
                        fontSize: 46, 
                        fontFamily: 'Poppins', 
                        fontWeight: '700', 
                    }}><wbr/> Own Your Community
                    </span>
                    <br/>
                </div>
                <Form
                placeholder="Your Email"
                text="Contact Sales"
                buttonColor="#47719D"
                buttonTextColor="#ffffff"
                width="100%"
                maxWidth="400px"
                formName="LIST"
                formValue="SalesRequest"
                formType="MedTech"
                /> 
            </StyledText>
        </TitlePanel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '45px', lineHeight: ''}}>
                    <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Empower
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Your Key Opinion Leaders
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForMedTechs/bkg1.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/KOLs.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Armed with the Orchid tool-kit, your KOLs become educators without geographic limits. Put them in a sponsored Orchid Group with key sales targets, and watch what happens when those surgeons become convinced that your technology is safer, simpler, and quicker to use. Put them in a Group with your Sales Reps, and you have a new sales training tool. 
                    </span>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '45px', lineHeight: '70px'}}>
                    <span style={{color: "#27533E", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Teach More
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> With Less
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes  src="/images/store/ForTeams/bkg2.svg" alt="Orchid Surgical" />
                <div style={{position: 'relative', width: '90%', maxWidth:'400px', height: '250px'}}>
                <img src="/images/store/ForMedTechs/teach_bkg.png" alt="Orchid Surgical" 
                    style={{position: 'absolute', width: '90%', top: '50%', right: '50%', transform: 'translate(50%, -50%)'}}/>
                <img src="/images/store/ForMedTechs/message1.png" alt="Orchid Surgical" 
                    style={{position: 'absolute', height: '164px', top: 0, left: 'calc(73%)', transform: 'translate(-50%, 0%)'}}/>
                <img src="/images/store/ForMedTechs/message2.png" alt="Orchid Surgical" 
                    style={{position: 'absolute', height: '200px', top: 0, left: 'calc(40%)', transform: 'translate(-50%, 0%)'}}/>
                </div>
            </div>
            <div className='text-container'>
            <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    It’s not that there’s something wrong with spending $30,000 on a cadaver course for 12 surgeons-- we just think you can do better. Layer sponsored Orchid Groups on top of a traditional course to immortalize the content, and give your course a second life. Or replace the course with real-life content all together, all on Orchid.                    </span>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '45px', lineHeight: '70px'}}>
                    <span style={{color: "#431AE7", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                    Build A Library
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Your Company Is Proud Of
                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg3.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/library_medtech.png" alt="Orchid Surgical" style={{width: '100%', maxWidth:'550px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Everything your surgeons do with Orchid helps to build a living, growing, video library that showcases your products in action. Techniques and technologies are constantly improving, and with Orchid, so will your MedEd content. 
                    </span>
                </div>
            </div>            
        </Panel>
        <Footer />
        </MobileStyledLandingPage>
        <StyledLandingPage>
        <HeaderBackground>
            <img src="/images/store/ForMedTechs/header_bkg.svg" alt="Orchid Surgical" height ='100%'/>
        </HeaderBackground>
        <Header />
        <TitlePanel>
        <GlassContainer>
            <StyledText>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <span style={{
                        color: '#303030', 
                        fontSize: 55, 
                        fontFamily: 'Lato', 
                        fontWeight: '600', 
                    }}
                    >
                        One Platform For Your Company To<br/>
                    </span>
                    <span style={{
                        color: '#4127AB', 
                        fontSize: 55, 
                        fontFamily: 'Poppins', 
                        fontWeight: '700', 
                    }}>Own Your Community
                    </span>
                    <br/>
                </div>
                <Form
                placeholder="Your Email"
                text="Contact Sales"
                buttonColor="#47719D"
                buttonTextColor="#ffffff"
                width="100%"
                maxWidth="400px"
                formName="LIST"
                formValue="SalesRequest"
                formType="MedTech"
                /> 
            </StyledText>
        </GlassContainer>
        </TitlePanel>
        <Panel>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForMedTechs/bkg1.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/KOLs.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                    Empower
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Your Key Opinion Leaders
                    </span>
                </div>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Armed with the Orchid tool-kit, your KOLs become educators without geographic limits. Put them in a sponsored Orchid Group with key sales targets, and watch what happens when those surgeons become convinced that your technology is safer, simpler, and quicker to use. Put them in a Group with your Sales Reps, and you have a new sales training tool. 
                    </span>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#27533E", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "nowrap"}}>
                    Teach More
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> With Less
                    </span>
                </div>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    It’s not that there’s something wrong with spending $30,000 on a cadaver course for 12 surgeons-- we just think you can do better. Layer sponsored Orchid Groups on top of a traditional course to immortalize the content, and give your course a second life. Or replace the course with real-life content all together, all on Orchid.                    </span>
                </div>
            </div>
            <div className='content-container'>
                <BackgroundShapes  src="/images/store/ForTeams/bkg2.svg" alt="Orchid Surgical" />
                <div style={{position: 'relative', width: '100%', height: '350px'}}>
                <img src="/images/store/ForMedTechs/teach_bkg.png" alt="Orchid Surgical" 
                    style={{position: 'absolute', width: '100%', top: '50%', right: '50%', transform: 'translate(50%, -50%)'}}/>
                <img src="/images/store/ForMedTechs/message1.png" alt="Orchid Surgical" 
                    style={{position: 'absolute', width: '75%', top: '28%', right: 'calc(10% + 50px)', transform: 'translate(50%, -50%)'}}/>
                <img src="/images/store/ForMedTechs/message2.png" alt="Orchid Surgical" 
                    style={{position: 'absolute', width: '100%', top: '35%', left: '-65%', transform: 'translate(50%, -50%)'}}/>
                </div>
            </div>
        </Panel>
        <Panel>
            <div className='content-container'>
                <BackgroundShapes src="/images/store/ForTeams/bkg3.svg" alt="Orchid Surgical" />
                <img src="/images/store/ForMedTechs/library_medtech.png" alt="Orchid Surgical" style={{width: '100%', maxWidth: '800px', minWidth: '500px'}}/>
            </div>
            <div className='text-container'>
                <div style={{textAlign: 'left', fontSize: '68px', lineHeight: '70px'}}>
                    <span style={{color: "#431AE7", fontFamily: "Poppins", fontWeight: "700", whiteSpace: "wrap"}}>
                    Build A Library
                    </span>
                    <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600", whiteSpace: "wrap"}}>
                    <wbr/> Your Company Is Proud Of
                    </span>
                </div>
                <div style={{textAlign: 'left'}}>
                    <span style={{
                    textAlign: "left",
                    color: '#4A4A4A',
                    fontSize: 18,
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    textTransform: 'none',
                    }}> 
                    Everything your surgeons do with Orchid helps to build a living, growing, video library that showcases your products in action. Techniques and technologies are constantly improving, and with Orchid, so will your MedEd content. 
                    </span>
                </div>
            </div>            
        </Panel>
        <Footer />
        </StyledLandingPage>
        </>
    );
    };

export default ForMedTechs;

const TitlePanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    height: 100%;
    margin-bottom: 50px;
    max-width: 1600px;
`;

const StyledText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 50px;
    gap: 50px;

    @media (max-width: 900px) {
        padding: 50px 10px;
    }

`;



