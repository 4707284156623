import {get, post, patch, del} from './common.js';

// Groups

export function getGroupsAdmin(query_params={}) {
    return get('/groups/', 'admin', query_params=query_params);
}

export function getGroupAdmin(id) {
    return get('/groups/' + id + '/', 'admin');
}

export function createGroupAdmin(data) {
    return post('/groups/', data, 'admin');
}

export function updateGroupAdmin(id, data) {
    return patch('/groups/' + id + '/', data, 'admin');
}

export function deleteGroupAdmin(id) {
    return del('/groups/' + id + '/', 'admin');
}

// Group Members

export function getGroupMembersAdmin(query_params={}) {
    return get('/group_members/', 'admin', query_params=query_params);
}

export function getGroupMemberAdmin(id) {
    return get('/group_members/' + id + '/', 'admin');
}

export function createGroupMemberAdmin(data) {
    return post('/group_members/', data, 'admin');
}

export function updateGroupMemberAdmin(id, data) {
    return patch('/group_members/' + id + '/', data, 'admin');
}

export function deleteGroupMemberAdmin(id) {
    return del('/group_members/' + id + '/', 'admin');
}
