import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { dateFromTimestamp } from '../services/util/metadata.js';
import { Link, useNavigate } from 'react-router-dom';
import { COLORS } from '../values/colors.js';
import { getThumbnailUrl } from '../services/api/attachments.js';
import { hmsTextFromSeconds } from '../services/util/metadata.js';

const VideoListItem = ({ video, handleVideoSelect, selectedVideo }) => {

    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // setVideoThumbnail(video.thumbnail.download_link);
        setVideoThumbnail(getThumbnailUrl(video));
    }, [video]);

    return(
        <ItemContainer 
            className="video-list-item" 
            onClick={(e) => {handleVideoSelect(video)}}
            >
                <ThumbnailContainer
                    onClick={(e) => {if (video.status===3) {navigate(`/viewer/${video.id}`);} else {e.stopPropagation();}}}
                >    
                    <Thumbnail 
                        src={videoThumbnail} 
                        // alt={video.case.case_name} 
                    />
                    {video.status != 3 &&
                    <StatusContainer>
                        {video.status === 1 ? (
                            <span>Uploading</span>
                        ) : (
                            <span>Processing</span>
                        )}
                    </StatusContainer>
                    } 
                </ThumbnailContainer>
                <VideoByline>
                    <span><h4
                        onClick={(e) => {if (video.status===3) {navigate(`/viewer/${video.id}`);} else {e.stopPropagation();}}}
                    >{video.case.case_name}</h4></span>
                    <span>
                        {
                            video.case.owner.first_name + ' ' + 
                            video.case.owner.last_name + '  \u2022  ' + 
                            dateFromTimestamp(video.created_on)
                        }
                    </span>
                    <span>
                        <span>{hmsTextFromSeconds(video?.user_metadata?.duration, 'hm')}</span>
                        <span>
                            {(video.markers?.length > 0) && 
                                (`  \u2022  ` + video.markers?.length + 
                                ` chapter` + (video.markers?.length>1 ? `s` : ``))
                            }
                        </span>
                    </span>
                </VideoByline>
            <TagsArea>   
            {video.group?.name && (
                <Link to={`/group/${video.group.id}`}>
                    <GroupAttribution onClick={(e)=>e.stopPropagation()}>
                        <span>{video.group.name}</span>
                    </GroupAttribution>
                </Link>
            )}
            </TagsArea>    
                <DetailsContainer className="video-list-item-details">
                    <span>{video.case.description}</span>
                </DetailsContainer>
        </ItemContainer>
    );
;}

const ItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    background-color: #fff;
    cursor: pointer;
    color: #333;

 `;

const ThumbnailContainer = styled.div`
    position: relative;
    display: inline-flex;
    border-radius: 0px;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    @media (min-width: 460px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const Thumbnail = styled.img`
    height: auto;
    width: 100%;

    @media (min-width: 460px) {
        border-radius: 12px;
    }
`;

const TagsArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const GroupAttribution = styled.div`
    display: inline-block;
    font-size: 13px;
    color: white;
    border-radius: 10px;
    padding: 1px 5px;
    z-index: 1;
    background-color: ${COLORS.orchid_indigo};
`;

const StatusContainer = styled.div`
    display: inline-block;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 600;
    color: white;
    border-radius: 10px;
    padding: 1px 5px;
    z-index: 2;
    background-color: ${COLORS.orchid_dark};
`;

const VideoByline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    font-size: 15px;
    color: #666;
    margin-left: 10px;
    width: 100%;
    text-align: left;
    overflow: hidden;

    h4 {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 3px;
    }
`;

const DetailsContainer = styled.div`
    margin: 0;
    padding: 5px 12px;
    text-align: left;
    font-size: 15px;
    width: 100%;

    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

`;

const MobileVideoList = ({ attachments, title, emptyMessage, searchable }) => {

    const [displayedVideos, setDisplayedVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [searchTerms, setSearchTerms] = useState('');

    useEffect(() => {
        setDisplayedVideos(attachments);
    }, [attachments]);

    const handleVideoSelect = (video) => {
        if (selectedVideo === video) {
            setSelectedVideo(null);
            return;
        }
        setSelectedVideo(video);
    }

    const handleSearch = (e) => {
        setSearchTerms(e.target.value);
        if (e.target.value === '') {
            setDisplayedVideos(attachments);
        } else {
            const words = e.target.value.split(' ');
            const filteredVideos = attachments.filter((video) => {
                let match = Array(words.length).fill(false);
                words.forEach((word, idx) => {
                    if (video.case.case_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.description.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.owner.first_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.owner.last_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.created_on.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.markers.length > 0) {
                        video.markers.forEach((marker) => {
                            if (marker.name.toLowerCase().includes(word.toLowerCase())) {
                                match[idx] = true;
                            }
                            if (marker.description.toLowerCase().includes(word.toLowerCase())) {
                                match[idx] = true;
                            }
                        });
                    }
                });
                return match.every(value => value === true);
            });
            setDisplayedVideos(filteredVideos);
        }
    }

    return ( 
        <VideoListContainer>
            {searchable &&
            <SearchBar>
                <input 
                    type="text" 
                    placeholder="Search videos..." 
                    value={searchTerms}
                    onChange={handleSearch}
                />
            </SearchBar>
            }
            <VideoItemList>
                {displayedVideos.map((video, index) => (
                    <VideoListItem 
                        key={index} 
                        video={video} 
                        handleVideoSelect={handleVideoSelect}
                        selectedVideo={selectedVideo}
                    />
                ))}
                {attachments.length === 0 && 
                <NoVideosMessage>
                    {emptyMessage}
                </NoVideosMessage>}
            </VideoItemList>
        </VideoListContainer>
     );
}

const VideoListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    /* height: calc(100% - 60px); */
    /* height: fit-content; */
    /* overflow: visible; */

    @media (min-width: 460px) {
        max-width: 460px;
        align-self: center;
    }
`;

const SearchBar = styled.div`
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: stretch;
    width: inherit;
    margin: 10px;
    padding: 7px;
    margin-top: 0px;
    width: calc(100% - 20px);
    height: 40px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);

    input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        color: #333;
    }
`;

const VideoItemList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    /* height: 100%; */
    overflow-x: hidden;
    gap: 5px;
`;

const NoVideosMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 15px;
    width: 100%;
    height: 100%;
`;

export default MobileVideoList;