import styled from 'styled-components';
import { useRef } from 'react';

const DropFiles = ({handleChange, label}) => {

  const fileInput = useRef(null);

    //Drop Handler
    function dropHandler(ev) {
        console.log("File(s) dropped");
        
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        // Return the background color to default
        ev.target.style.removeProperty('background-color');
      
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          [...ev.dataTransfer.items].forEach((item, i) => {
            // If item is a file and is a .mp4 file
            if (item.kind === "file" && item.type === "video/mp4") {
              const file = item.getAsFile();
              console.log(`… file[${i}].name = ${file.name}`);
            }
          });
        } else {
          // Use DataTransfer interface to access the file(s)
          [...ev.dataTransfer.files].forEach((file, i) => {
            console.log(`… file[${i}].name = ${file.name}`);
          });
        }

        //Pass files to parent component
        handleChange(ev.dataTransfer.files)

        // Pass event to removeDragData for cleanup
        removeDragData(ev)

    }

    //DragEnter Handler to set background color
    function dragEnterHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        // Set the background color to indicate drag over
        ev.target.style.backgroundColor = "#ddd";
    }
    
    //Dragover Handler to prevent default behavior
    function dragOverHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }

    //DragLeave Handler to reset background color
    function dragLeaveHandler(ev) {
                // Return the background color to default
                ev.target.style.removeProperty('background-color')
    }

    //Remove drag data
    function removeDragData(ev) {
        console.log("Removing drag data");
      
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to remove the drag data
          ev.dataTransfer.items.clear();
        } else {
          // Use DataTransfer interface to remove the drag data
          ev.dataTransfer.clearData();
        }
    }

    function handleClick() {
      fileInput.current.onchange = _ => {
        [...fileInput.current.files].forEach((file, i) => {
          console.log(`… file[${i}].name = ${file.name}`);
        });
        // let files = Array.from(fileInput.current.files);
        // console.log(files);
        // console.log('hi')
        handleChange(fileInput.current.files);
        };
      fileInput.current.click();
    }

    return ( 
      <Background>
        <input type="file" multiple accept={'video/mp4'} ref={fileInput} style={{display: 'none'}}/>
        <DropZone 
            id="drop_zone" 
            onDrop = {dropHandler}
            onDragEnter = {dragEnterHandler}
            onDragOver={dragOverHandler}
            onDragLeave={dragLeaveHandler}
            onClick={handleClick}>
            <div
              className="text-center"
              style={{opacity: 0.4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', pointerEvents: 'none', height: '100%', width: '100%'}}
            >
              <img src="/images/icons/hard-drive.svg" alt="upload to cloud" />
              <img src="/images/icons/arrow-narrow-right.svg" alt="upload to cloud" />
              <img src="/images/icons/cloud-blank-01.svg" alt="upload to cloud" />
            </div>
        </DropZone>
      </Background>
     );
}

const Background = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    /* background-image: url('/images/upload-to-cloud.png'); */
    background-repeat: no-repeat;
    background-position: center;
    position: relative;    
`;

const DropZone = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bbb;
    font-size: 1.2rem;
    cursor: pointer;
    
`
 
export default DropFiles;