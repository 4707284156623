import { useState, useRef, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

function ConfirmationModal(props) {
    const [isAttested, setIsAttested] = useState(false);
    const [signature, setSignature] = useState('');

    const checkValidity = () => {
        if (props.attestationRequired && !isAttested) {
            return false;
        }
        if (props.signatureRequired && signature !== props.signatureChallenge) {
            return false;
        }
        return true;
    }

    return (
        <Modal
            size="md"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MessageBox>
                    {props.message}
                </MessageBox>
                <br />
                <Form>
                    {props.attestationRequired ? (
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check 
                                type="checkbox" 
                                checked={isAttested}
                                label={props.attestationMessage} 
                                onChange={(e) => setIsAttested(e.target.checked)}/>
                        </Form.Group>
                    ) : (null)}
                    {props.signatureRequired ? (
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Label>{props.signatureMessage}</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={signature}
                                onChange={(e) => setSignature(e.target.value)}
                            />
                        </Form.Group>
                    ) : (null)}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cancel</Button>
                <Button 
                    onClick={() => {props.onConfirm(); props.onHide();}}
                    disabled={!checkValidity()}
                >{props.buttonTitle}</Button>
            </Modal.Footer>
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    buttonTitle: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    attestationRequired: PropTypes.bool,
    attestationMessage: PropTypes.string,
    signatureRequired: PropTypes.bool,
    signatureMessage: PropTypes.string,
    signatureChallenge: PropTypes.string
};

ConfirmationModal.defaultProps = {
    title: 'Confirmation',
    message: 'Are you sure?',
    buttonTitle: 'Confirm',
    attestationRequired: false,
    attestationMessage: 'I want to do this',
    signatureRequired: false,
    signatureMessage: 'Please type "Yes":',
    signatureChallenge: 'Yes'
};

export default ConfirmationModal;

const MessageBox = styled.div`
    white-space: pre-line;
`;
