import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';
import { joinWaitlist } from '../services/api/waitlist';
import mixpanel from 'mixpanel-browser';

const Waitlist = () => {

    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        waitlist_usecase: ''
    });
    const [userDataChanged, setUserDataChanged] = useState(null);
    const [disabled, setIsDisabled] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        // Update user data when input values change
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
        setUserDataChanged({...userDataChanged,
            [e.target.name]: true});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.form.checkValidity()) {
            setSuccessMessage('Please fill out all required fields.');
            return;
        }
        let dataToUpload= {};
        for (const [key, value] of Object.entries(userData)) {
            // if (userDataChanged[key]) 
                dataToUpload[key] = value;

        }
        joinWaitlist(dataToUpload).then((data) => {
            if (!data) { 
                console.log('error')
                setSuccessMessage('There was an error submitting your information. Please try again later.');
            } else {
                console.log('success')
                setUserData(data);
                setUserDataChanged(null);
                setSuccessMessage('Your information has been submitted successfully. Thank you!');
                setIsDisabled(true);
                mixpanel.track('Joined Waitlist', {'Source': 'Waitlist'});
            }
        })
    };

    return (
        <PageContainer>
            <FormWrap>
            <h1>Join our Waitlist</h1>
            <p>
            <b>Sign up to be the first to know when we're available! </b> 
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id='infoInfo'>We will never share your information without your consent.</Tooltip>}
                onClick={(e) => e.preventDefault()}
            >
                <InfoSymbol>i</InfoSymbol>
            </OverlayTrigger>
            </p>
            <Form>

                <Form.Group className="mb-3">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control name="first_name" type="text" value={userData.first_name} onChange={handleInputChange} required disabled={disabled}/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control name="last_name" type="text" value={userData.last_name} onChange={handleInputChange} required disabled={disabled}/>
                </Form.Group>
 
                <Form.Group className="mb-3">
                    <Form.Label>Email address *</Form.Label>
                    <Form.Control name="email" type="email" value={userData.email} onChange={handleInputChange} required disabled={disabled}/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>What would you like to do with Orchid? *</Form.Label>
                    <Form.Select name="waitlist_usecase" value={userData.waitlist_usecase} onChange={handleInputChange} required disabled={disabled}>
                        <option value=""></option>
                        <option value="upload">Capture and share surgical video with colleagues</option>
                        <option value="watch">Watch surgical video taken by others</option>
                        <option value="both">Both!</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>How did you hear about us? *</Form.Label>
                    <Form.Select name="how_did_you_hear" value={userData.how_did_you_hear} onChange={handleInputChange} required disabled={disabled}>
                        <option value=""></option>
                        <option value="colleague">Colleague</option>
                        <option value="social_media">Social Media</option>
                        <option value="search_engine">Search Engine</option>
                        <option value="conference">Conference</option>
                        <option value="other">Other</option>
                    </Form.Select>
                </Form.Group>

                <SubmitBox>
                    <Button variant="primary" type="submit" onClick={handleSubmit} disabled={disabled}>
                        Submit
                    </Button>
                    {successMessage && <span>{ '\u2000' + successMessage }</span>}
                </SubmitBox>
                </Form>
                </FormWrap>
        </PageContainer>
    );
};

export default Waitlist;

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    height: 100vh;
    background-image: url("/images/bkg_camera.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: auto;
`;

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(256,256,256,0.8);
    color: #000;
    padding: 2rem;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    min-width: 500px;
    min-height: 600px;
    max-width: 50vw;
    max-height: 80vh;
    margin: auto;

`;

const SubmitBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
`;

const InfoSymbol = styled.button`
    border: none;
    border-radius:8px;
    height: 16.5px;
    text-align: center;
    font-size: 11px;
    color: white;
    cursor: pointer;
    font-weight: bolder;
    background-color: #007bff;
    width: 16.5px;
`